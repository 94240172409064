/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo ,useEffect} from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { NODE_API_URL,BASE_URL } from "../../../../../env_config";
import axios from "axios";
import moment from "moment-timezone";
import { FaRegBell } from "react-icons/fa";
import Cookies from "js-cookie"
const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserNotificationsDropdown(props) {
  const [key, setKey] = useState("Alerts");
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
  const [notificationdata,setNotificationData] = useState([])
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);
  useEffect(() => {
    
    axios({
        method: "GET",
        url: `${NODE_API_URL}/EUR/api-for-notification`,
        headers: { 
          "Content-Type": "application/json" ,
        "Authorization": `Bearer ${Cookies.get("cust_auth_token")}`
        }
    }).then(res => {
        if(res && res.data && res.data.data){
            setNotificationData(res.data.data)
        }
    }).
    catch(error => {
      if(error?.response?.data?.message === "UnAuthorized!"){
        var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        if(cookies[i] && !cookies[i].includes("view")){
          var equals = cookies[i].indexOf("=");
          var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }
      
      window.location.replace(`${BASE_URL}/logout`);
    }
    })
  },[])
  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Notifications1.svg")} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  Notifications
                </Tooltip>
              }
            >
              <div
                className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                id="kt_quick_notifications_toggle"
              >
                <span className="svg-icon ">
                  <FaRegBell className="text-dark font-size-h3"/>
                </span>
                {/* <span className="pulse-ring"></span>
                <span className="pulse-ring" /> */}
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up">
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column py-5 border-bottom bgi-size-cover bgi-no-repeat rounded-top"
                // style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-dark">{props.language.NOTIFICATIONS_NOTIFICATION}</span>
                </h4>
                
              </div>
              <div>

            { notificationdata.length > 0? 
            notificationdata.map(((value,index) => (
              <a className="dropdown-item" key={index + 1}>
					<div className="message" style={{width:"100%"}}>
						<div>
              <small className="text-muted float-right mt-1">
                {value.created_date}
                {/* { value.created_date ? moment.tz(value.created_date, props.userinfo.timezone ? props.userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"} */}
              </small>
              </div>
						<div className="font-weight-bold">{value.title}</div>
						<div className="small text-muted">{value.body}</div>
					</div>
				</a>
            )))
            :<div className="dropdown-item">
              <div className="d-flex flex-center">No Notification to show</div>
              </div>}
                
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
