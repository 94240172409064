import React, { useState,useEffect,useRef } from "react";
import { TextField } from "@material-ui/core";
import { Field } from "formik";
import axios from "axios";
import Cookies from "js-cookie";
import { NODE_API_URL,BASE_URL } from "../../../../../env_config";
export const CaratPcs = ({ language, formik,caratErr,setCaratErr,pcsErr,setPcsErr,minCaratErr,setMinCaratErr }) => {
    const [caratBox, setCaratBox] = useState(true);
    const [pcsBox, setPcsBox] = useState(false);
    const [carat,setCarat]= useState("00")
    const [pcs,setPcs]= useState("00")
    const [caratText,setCaratText]= useState("00")
    const [pcsText,setPcsText]= useState("00")
    const [dimondType, setDiamondType] =useState('');
    const [caratPcsErr, setCaratPcsErr] =useState(false)
    const [buyType, setBuyType] = React.useState('carat');
    const [errorMsg, setErrorMsg] = useState('');

    const errorEle = useRef(null);
    const errorEleMsg = useRef(null);
     useEffect(() => {
  
        if(window.location.pathname === "/parcel-diamond"){
            setDiamondType("PN")
        }
        if(window.location.pathname === "/lab-parcel-diamond"){
            setDiamondType("PL")
        }
      
    },[])
    useEffect(() => {
  
        if(caratErr){
            errorEle.current.focus();

            errorEleMsg.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    },[caratErr])

    useEffect(() => {
        setCarat((formik.values.carat_value==="" || formik.values.carat_value===undefined ) ? "00":formik.values.carat_value)
        setPcs(formik.values.pcs_value==="" || formik.values.pcs_value===undefined  ? "00" :formik.values.pcs_value)
    },[formik.values.carat_value,formik.values.pcs_value])
  
    const caratBoxFn = () => {
        setCaratBox(true);
        setPcsBox(false);
        setBuyType('carat')
        formik.setFieldValue("pcs_value","")
        formik.setFieldValue("type","carat")
    };

    const pcsBoxFn = () => {
        setCaratBox(false);
        setPcsBox(true);
        setBuyType('pcs')
        formik.setFieldValue("carat_value","")
        formik.setFieldValue("type","pcs")
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const getCaratVal = (e)=>{
        e.persist();
        setTimeout( ()=> {
            if((e.target.value !=="" ) && (e.target.value < 0.05)){
                setMinCaratErr(true)
            }else{
                setMinCaratErr(false)
            }
        }, 1000)

        if (formik.values.shape === '' || formik.values.range_value_from === '' || formik.values.range_value_to === ''){
            setCaratPcsErr(true)
        }else{
            setCaratPcsErr(false)
        }
        e.target.value === formik.values.carat_value ? formik.setFieldValue("carat_value","") : formik.setFieldValue("carat_value",e.target.value)
        setCarat(e.target.value)
        setCaratText(e.target.value)
        formik.setFieldValue("carat_value",e.target.value)
        const finalData = 
            {
                "shape": formik.values.shape,
                "range_value_from": formik.values.range_value_from,
                "range_value_to": formik.values.range_value_to,
                "carat_value": e.target.value,
                "diamond_type":dimondType    
            }

            function handleResponse(response) {
                if (response && response.data) {
                    if (response.data.data === null) {
                        setPcs("00");
                        setPcsText("00");
                    } else {
                        setPcs(parseFloat(response.data.data));
                        setPcsText(parseFloat(response.data.data));
                    }
                    setTimeout(() => {
                        if (parseFloat(response.data.data) === 0 || response.data.data === null) {
                            setPcsErr(true);
                            setErrorMsg('0 Pcs -  Please increase Qty');
                        } else {
                            setPcsErr(false);
                            setErrorMsg('');
                        }
                    }, 1000);
                }
            }
            
            axios({
                method: "GET",
                url: `${NODE_API_URL}/EUR/parcelCaratToPcandViceVersa`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                },
                params: finalData,
            })
            .then((res) => {
                if (res && res.data) {
                    handleResponse(res);
                   
                }
            }).catch(error => {
                handleResponse(error.response);
                if(error?.response?.data?.message === "UnAuthorized!"){
                  var cookies = document.cookie.split(";");
                for (var i = 0; i < cookies.length; i++) {
                  if(cookies[i] && !cookies[i].includes("view")){
                    var equals = cookies[i].indexOf("=");
                    var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                  }
                }
                
                window.location.replace(`${BASE_URL}/logout`);
              }
              })
        }

    const getPcsVal = (e)=>{

        if (formik.values.shape === '' || formik.values.range_value_from === '' || formik.values.range_value_to === ''){
            setCaratPcsErr(true)
        }else{
            setCaratPcsErr(false)
        }

        e.target.value === formik.values.pcs_value ?formik.setFieldValue("pcs_value","") : formik.setFieldValue("pcs_value",e.target.value)
        setPcs(e.target.value)
        setPcsText(e.target.value)
        formik.setFieldValue("pcs_value",e.target.value)
        e.persist();
        const finalData = 
            {
                "shape": formik.values.shape,
                "range_value_from": formik.values.range_value_from,
                "range_value_to": formik.values.range_value_to,
                "pcs_value": e.target.value,
                "diamond_type":dimondType    
            }
            const handleResponse = (e, data) => {
                if ((e.target.value !== "" && parseFloat(data) === 0)||data === null) {
                    setPcsErr(true);
                    setErrorMsg('0 Pcs - Please increase Qty');
                } else {
                    setPcsErr(false);
                    setErrorMsg('');
                }
            
                if (e.target.value !== "" && parseFloat(data) < 0.05) {
                    setMinCaratErr(true);
                } else {
                    setMinCaratErr(false);
                }
            };
            
            axios({
                method: "GET",
                url: `${NODE_API_URL}/EUR/parcelCaratToPcandViceVersa`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                },
                params: finalData,
            })
                .then((res) => {
                    if (res && res.data) {
                        setCarat(parseFloat(res.data.data));
                        setCaratText(parseFloat(res.data.data));
                       
                        setTimeout(() => {
                            handleResponse(e, res.data.data);
                        }, 1000);
                    }
                })
                .catch(error => {
                    setTimeout(() => {
                        handleResponse(e, null); // Pass null or appropriate value based on your requirements
                    }, 1000);
                    if(error?.response?.data?.message === "UnAuthorized!"){
                      var cookies = document.cookie.split(";");
                    for (var i = 0; i < cookies.length; i++) {
                      if(cookies[i] && !cookies[i].includes("view")){
                        var equals = cookies[i].indexOf("=");
                        var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                      }
                    }
                    
                    window.location.replace(`${BASE_URL}/logout`);
                  }
                  })
            
        }

    return (
        <>
            <div className="row mt-5">
                <div className="col-12 d-md-flex justify-content-between flex-wrap my-5 align-items-center">
                    <div>
                        <label className="search_btn_check">
                            <Field
                                type="radio"
                                name="caratPcs"
                                checked={caratBox}
                                onClick={(e) => caratBoxFn()}
                            />
                            <div className="parcelBox selectBox">
                                <span>Carat</span>
                            </div>
                        </label>
                        <label className="search_btn_check">
                            <Field
                                type="radio"
                                name="caratPcs"
                                checked={pcsBox}
                                onClick={(e) => pcsBoxFn()}
                                
                            />
                            <div className="parcelBox selectBox">
                                <span>Pcs</span>
                            </div>
                        </label>
                    </div>
                    <div>
                        {caratBox ? (
                            <div className="row">
                                <div className="col-md-12">
                                    <TextField
                                        ref={errorEle}
                                        margin="dense"
                                        type="text"
                                        label="Please Enter Carat"
                                        name="carat_value"
                                        variant="outlined"
                                        className={`w-100 ${getInputClasses( "carat_value" )} ${caratPcsErr || caratErr ? "border-danger": ""}`}
                                        {...formik.getFieldProps("carat_value")}
                                        sx={{ width: "10px" }}
                                        onChange={(e) => {
                                            const regex = /^(\d*\.\d{0,2}|\d+)$/;
                                            if (e.target.value && !regex.test(e.target.value)) {
                                                e.preventDefault()
                                                return
                                            }
                                            getCaratVal(e);
                                            e.target.value !==""? setCaratErr(false):setCaratErr(true)
                                            pcsText !==""? setPcsErr(false):setPcsErr(true)
                                            
                                           
                                        }}
                                        value={carat==="00"?'':carat}
                                        error={errorMsg}
                                        helperText={errorMsg || ''}
                                    />
                                    {minCaratErr ? (
                                        <div  className="ml-4 small text-danger">
                                            Minimum Order Quantity 0.05
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        {pcsBox ? (
                            <div className="row">
                                <div className="col-md-12">
                                    <TextField
                                        ref={errorEle}
                                        className={`w-100 ${getInputClasses( "pcs_value" )} ${caratPcsErr || caratErr ? "border-danger": ""}`}
                                        {...formik.getFieldProps("pcs_value")}
                                        margin="dense"
                                        type="number"
                                        label="Please Enter Pcs"
                                        name="pcs_value"
                                        variant="outlined"
                                        sx={{ width: "10px" }}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        onKeyDown={(e) => {
                                            // Check if the pressed key is a dot
                                            if (e.key === '.') {
                                              e.preventDefault(); // Prevent typing the dot
                                              return;
                                            }
                                          }}
                                        onChange={(e) => {
                                            getPcsVal(e);
                                            const inputValue = e.target.value;
                                            if(/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
                                                setPcs(inputValue);
                                            }
                                            e.target.value !==""? setCaratErr(false):setCaratErr(true)
                                            e.target.value !==""? setPcsErr(false):setPcsErr(true)
                                        }}
                                        value={pcs==="00"?'':pcs}
                                        error={errorMsg}
                                        helperText={errorMsg || ''}
                                    />
                                    {minCaratErr ? (
                                        <div className="ml-4 small text-danger">
                                            Minimum Order Quantity 0.05
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div>
                        <div className="font-weight-bold small">
                        {caratText !== null && pcsText !== null ? parseFloat(caratText).toFixed(2) || "00.00" : "00.00"} Cts. =
                        {caratText !== null && pcsText !== null ? parseFloat(pcsText) || "00" : "00"} Pcs *
                        </div>
                    </div>
                </div>

                {/* <div className="col-12">
                    {pcsErr ? (
                        <div className="text-danger" ref={errorEleMsg}>
                           0 Pcs not allowed
                        </div>
                    ) : null}
                </div> */}
                <div className="col-12">
                    {caratErr ? (
                        <div className="text-danger" ref={errorEleMsg}>
                           This field is required
                        </div>
                    ) : null}
                </div>
                <div className="col-12">
                    {caratPcsErr ? (
                        <div className="text-danger">
                          Above filter criteria is required for this change
                        </div>
                    ) : null}
                </div>
                
            </div>
        </>
    );
};
