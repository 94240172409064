import React,{useEffect, useMemo, useState} from "react";
import { Modal, Button } from "react-bootstrap";
import { useFormik,FormikProvider } from "formik";
import PhoneInputFieldFull from "./PhoneInputFieldFull";
import * as Yup from "yup";
import axios from 'axios';
import Cookies from "js-cookie"
import { NODE_API_URL,BASE_URL } from "../../../env_config";
import Select from "react-select";
import { Backdrop,CircularProgress,makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));
export const AddShippingAddress = (props) => {
    // console.log(props.data)

    const classes = useStyles();    
    const [initialValues,setInitialValues] = useState({})
    const [openLoader,setOpenLoader] = useState(false)
    useEffect(() => {
       if(props.data){
        setInitialValues({
            user_id:props.data.id,
            company_name_official:props.data.company_name_official || '',
            shipping_address_1: props.editShipping.shipping_address_1 || '',
            shipping_address_2: props.editShipping.shipping_address_2 || '',
            shipping_address_3: props.editShipping.shipping_address_3 || '',
            shipping_city: props.editShipping.shipping_city || '',
            country: props.data.country||'',
            shipping_state: props.editShipping.shipping_state || '',
            shipping_zip_code: props.editShipping.shipping_zip_code || '',
            shipping_email: props.editShipping.shipping_email || '',
            shipping_phone: props.editShipping.shipping_phone || '',
            company_tax: props.data.company_tax||'',
            port_of_discharge:props.editShipping.port_of_discharge || '',
            default_address:props.editShipping.default_address?props.editShipping.default_address:!props.shippingaddress.length?"Y":"N",
            name_atten:props.editShipping.name_atten || '',
            id:props.editShipping.id || '',
        })
       } 
    },[props.data,props.editShipping,props.shippingaddress])
    const Schema = Yup.object().shape({
        name_atten: Yup.string("Enter Atten Name").required("Atten Name is required"),
        shipping_address_1: Yup.string("Enter Address 1").required("Address 1 is required"),
        shipping_email: Yup.string()
            .email("Wrong email format")
            .required("Email is required"),
        shipping_city: Yup.string("Enter City").required("City is required"),
        country: Yup.string("Enter Country").required("Country is required"),
        shipping_state: Yup.string("Enter State").required("Pincode is required"),
        shipping_zip_code: Yup.string("Enter zip code").required("zip code is required"),
        shipping_phone: Yup.string("Enter Telphone").required("Telephone is required"),
        company_tax: Yup.string("Company Tax/GST Id number").required("Company Tax/GST Id is required"),
        port_of_discharge: Yup.string("Enter Port Of Discharge").required("Port Of Discharge is required"),
    });
    const enableLoading = () => {
        // setLoading(true);
        setOpenLoader(true);
    };
    const disableLoading = () => {
        setOpenLoader(false);
    };
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }
        return "";
    };


    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: Schema,
        onSubmit: (values, { setSubmitting }) => {
            enableLoading()
            axios({
                method: "POST",
                url: `${NODE_API_URL}/EUR/iu-shipping-address`,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Cookies.get("cust_auth_token")}`
                },
                data:{
                    ...values
                }
            }).then((res) => {
                // console.log(res.data,"resres")
                if(res && res.data){
                    if(res.data.message === "UnAuthorized"){
                        localStorage.clear()
                        window.location.reload(true)
                    }
                    disableLoading()
                    props.setMount(!props.mount)
                    props.onHide()
                    props.setEditShipping({})
                    formik.resetForm()
                }
            }).catch(error => {
                if(error?.response?.data?.message === "UnAuthorized!"){
                  var cookies = document.cookie.split(";");
                for (var i = 0; i < cookies.length; i++) {
                  if(cookies[i] && !cookies[i].includes("view")){
                    var equals = cookies[i].indexOf("=");
                    var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                  }
                }
                
                window.location.replace(`${BASE_URL}/logout`);
              }
              })
            
        }
    });    
    return (
        <div>
           <Backdrop className={classes.backdrop} open={openLoader} >
                <div className="px-5 py-3 bg-white rounded shadow-sm d-flex justify-content-center align-items-center"><CircularProgress size="30px" color="secondary" /> <span className="pl-2 font-weight-bold text-dark">Processing..</span></div>
            </Backdrop>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="xl"
            >
                <Modal.Header className="font-size-h5 font-weight-bold">
                    {props.show} Shipping Address
                </Modal.Header>
                <Modal.Body className="pb-0">
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}> 
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group align-items-center row">
                                            <label className="col-md-4 font-weight-bold">
                                                Company Name <span className="text-danger">*</span>:
                                            </label>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    placeholder="Please Enter Company Name"
                                                    className={`form-control form-control-solid ${getInputClasses(
                                                        "company_name_official"
                                                    )}`}
                                                    name="company_name_official"
                                                    {...formik.getFieldProps("company_name_official")}
                                                    disabled
                                                    readOnly
                                                />
                                                {formik.touched.company_name_official &&
                                                formik.errors.company_name_official ? (
                                                    <div className="invalid-feedback">
                                                        {formik.errors.company_name_official}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group align-items-center row">
                                            <label className="col-md-4 font-weight-bold">
                                                Atten Name <span className="text-danger">*</span>:
                                            </label>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    placeholder="Please Enter Atten Name"
                                                    className={`form-control ${getInputClasses(
                                                        "name_atten"
                                                    )}`}
                                                    name="name_atten"
                                                    {...formik.getFieldProps("name_atten")}
                                                />
                                                {formik.touched.name_atten &&
                                                formik.errors.name_atten ? (
                                                    <div className="invalid-feedback">
                                                        {formik.errors.name_atten}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group align-items-center row">
                                            <label className="col-md-4 font-weight-bold">
                                                Address line 1 <span className="text-danger">*</span>:
                                            </label>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    placeholder="Please Enter Address line 1"
                                                    className={`form-control ${getInputClasses(
                                                        "shipping_address_1"
                                                    )}`}
                                                    name="shipping_address_1"
                                                    {...formik.getFieldProps("shipping_address_1")}
                                                />
                                                {formik.touched.shipping_address_1 &&
                                                formik.errors.shipping_address_1 ? (
                                                    <div className="invalid-feedback">
                                                        {formik.errors.shipping_address_1}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group align-items-center row">
                                            <label className="col-md-4 font-weight-bold">
                                                Address line 2:
                                            </label>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    placeholder="Please Enter Address line 2"
                                                    className={`form-control ${getInputClasses(
                                                        "shipping_address_2"
                                                    )}`}
                                                    name="shipping_address_2"
                                                    {...formik.getFieldProps("shipping_address_2")}
                                                />
                                                {formik.touched.shipping_address_2 &&
                                                formik.errors.shipping_address_2 ? (
                                                    <div className="invalid-feedback">
                                                        {formik.errors.shipping_address_2}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group align-items-center row">
                                            <label className="col-md-4 font-weight-bold">
                                                Address line 3:
                                            </label>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    placeholder="Please Enter Address line 3"
                                                    className={`form-control ${getInputClasses(
                                                        "shipping_address_3"
                                                    )}`}
                                                    name="shipping_address_3"
                                                    {...formik.getFieldProps("shipping_address_3")}
                                                />
                                                {formik.touched.shipping_address_3 &&
                                                formik.errors.shipping_address_3 ? (
                                                    <div className="invalid-feedback">
                                                        {formik.errors.shipping_address_3}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group align-items-center row">
                                            <label className="col-md-4 font-weight-bold">
                                               City <span className="text-danger">*</span> :
                                            </label>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    placeholder="Please Enter Shipping City"
                                                    className={`form-control ${getInputClasses(
                                                        "shipping_city"
                                                    )}`}
                                                    name="shipping_city"
                                                    {...formik.getFieldProps("shipping_city")}
                                                />
                                                {formik.touched.shipping_city &&
                                                formik.errors.shipping_city ? (
                                                    <div className="invalid-feedback">
                                                        {formik.errors.shipping_city}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group align-items-center row">
                                            <label className="col-md-4 font-weight-bold">
                                                State <span className="text-danger">*</span> :
                                            </label>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    placeholder="Please Enter Shipping State"
                                                    className={`form-control ${getInputClasses(
                                                        "shipping_state"
                                                    )}`}
                                                    name="shipping_state"
                                                    {...formik.getFieldProps("shipping_state")}
                                                />
                                                {formik.touched.shipping_state &&
                                                formik.errors.shipping_state ? (
                                                    <div className="invalid-feedback">
                                                        {formik.errors.shipping_state}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-group align-items-center row">
                                            <label className="col-md-4 font-weight-bold">
                                                Pin code <span className="text-danger">*</span> :
                                            </label>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    placeholder="Please Enter Pin Code"
                                                    className={`form-control ${getInputClasses(
                                                        "shipping_zip_code"
                                                    )}`}
                                                    name="shipping_zip_code"
                                                    {...formik.getFieldProps("shipping_zip_code")}
                                                />
                                                {formik.touched.shipping_zip_code &&
                                                formik.errors.shipping_zip_code ? (
                                                    <div className="invalid-feedback">
                                                        {formik.errors.shipping_zip_code}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                <div className="col-12">
                                        <div className="form-group align-items-center row">
                                            <label className="col-md-4 font-weight-bold">
                                                Country <span className="text-danger">*</span> :
                                            </label>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    placeholder="Please Enter country"
                                                    className={`form-control form-control-solid ${getInputClasses(
                                                        "country"
                                                    )}`}
                                                    name="country"
                                                    {...formik.getFieldProps("country")}
                                                    readOnly
                                                    disabled
                                                />
                                                {formik.touched.shipping_zip_code &&
                                                formik.errors.shipping_zip_code ? (
                                                    <div className="invalid-feedback">
                                                        {formik.errors.shipping_zip_code}
                                                    </div>
                                                ) : null}
                                                    {formik.touched.country &&
                                                    formik.errors.country ? (
                                                        <div className="invalid-feedback">
                                                            {formik.errors.country}
                                                        </div>
                                                    ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group align-items-center row">
                                            <label className="col-md-4 font-weight-bold">
                                                Email <span className="text-danger">*</span> :
                                            </label>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    placeholder="Please Enter Type of document"
                                                    className={`form-control ${getInputClasses(
                                                        "shipping_email"
                                                    )}`}
                                                    name="shipping_email"
                                                    {...formik.getFieldProps("shipping_email")}
                                                />
                                                {formik.touched.shipping_email &&
                                                formik.errors.shipping_email ? (
                                                    <div className="invalid-feedback">
                                                        {formik.errors.shipping_email}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <PhoneInputFieldFull
                                            label="Telephone"
                                            name="shipping_phone"
                                            country="in" // Set the default country code (e.g., India)
                                            placeholder="Please Enter Shipping TelePhone"
                                            required
                                        />
                                        
                                    </div>
                                    
                                    <div className="col-12">
                                        <div className="form-group align-items-center row">
                                            <label className="col-md-4 font-weight-bold">
                                                Company Tax/GST Id  <span className="text-danger">*</span>:
                                            </label>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    placeholder="Please Enter Company Tax/GST Id"
                                                    className={`form-control form-control-solid ${getInputClasses(
                                                        "company_tax"
                                                    )}`}
                                                    name="company_tax"
                                                    {...formik.getFieldProps("company_tax")}
                                                
                                                />
                                                {formik.touched.company_tax &&
                                                formik.errors.company_tax ? (
                                                    <div className="invalid-feedback">
                                                        {formik.errors.company_tax}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group align-items-center row">
                                            <label className="col-md-4 font-weight-bold">
                                                Port Of Discharge  <span className="text-danger">*</span>:
                                            </label>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    placeholder="Please Enter Port Of Discharge"
                                                    className={`form-control ${getInputClasses(
                                                        "port_of_discharge"
                                                    )}`}
                                                    name="port_of_discharge"
                                                    {...formik.getFieldProps("port_of_discharge")}
                                                
                                                />
                                                {formik.touched.port_of_discharge &&
                                                formik.errors.port_of_discharge ? (
                                                    <div className="invalid-feedback">
                                                        {formik.errors.port_of_discharge}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </FormikProvider>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <div className="checkbox-list">
                        <label className="checkbox">
                            <input type="checkbox" />
                            <span></span>
                            Set As Default
                        </label>
                    </div>
                    <div>
                        <button onClick={formik.handleSubmit} type="submit" className="btn btn-primary mr-2">Save</button>
                        <Button className="px-5"
                            variant="secondary"
                            onClick={() => {props.onHide();props.setEditShipping({})}}
                        >
                            close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
