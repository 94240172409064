/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useEffect} from "react";
import { BsLightningFill,BsCircle,BsCheckCircleFill, BsArrowLeftShort } from "react-icons/bs";
import { FaAward, FaCalendarAlt, FaUserCircle } from "react-icons/fa";
import { IoCloseSharp } from 'react-icons/io5';
// import StockAPI from "./StockAPI.json"
import { toAbsoluteUrl } from "../../_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ConfirmGoods,HoldPopup,SwalWarn } from "../Popup";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Checkbox } from "@material-ui/core";
import SVG from "react-inlinesvg";
import axios from "axios";
import { NODE_API_URL,BASE_URL } from "../../../env_config";
import { AddCompareDiamond } from "../Popup/AddCompareDiamond";
import CompareSkeleton from "./CompareSkeleton";
import { LazyImageComponent } from "../widgets/tiles/LazyImageComponent";
import { ImagePopupCommon } from "../widgets/tiles/ImagePopupCommon";
import { VideoPopupCommon } from "../widgets/tiles/VideoPopupCommon";
// import { LiaAwardSolid } from "react-icons/lia";
import { IoMdClose } from "react-icons/io";
import { CopyUrlAlert } from "../Popup/CopyUrlAlert";
import Cookies from "js-cookie";
import CertificateLink from "../CertificateLink";
export const Comparison = (props) => {
    
    const [comparedata,setCompareData] = React.useState([])
    const [showcompare,setShowCompare] = React.useState(false)
    const [update,setUpdate] = React.useState(false)
    const [selected, setSelected] = React.useState([]);
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
        setSelected(newSelected);
      };
      const [swalWarnOpen,setSwalWarnOpen] = useState(false)
      const [alertMsg,setAlertMsg] = useState()
      const [diamondselected,setDiamondSelected] = useState([])
      const [buypopup,setBuyPopUp] = useState(false)
      const [holdpopup,setHoldPopUp] = useState(false)
      const [buypopupcinr,setBuyPopUpInr] = useState(0)
      const [buypopupctotal,setBuyPopUpTotal] = useState(0)
      const [totalcarat,setTotalCarat] = useState(0)
      const [totalinrcar,setTotalInrCar] = useState(0)
      const [diamondcount,setDiamondcount] = useState(0)
      const [totalourprice,setTotalOurPrice] = useState(0)
      const [mainimageclick,setMainImageClick] = useState(false)
      const [loader,setLoader] = useState(true)
      const [removedata,setRemoveData] = useState(null)
      const userData = JSON.parse(localStorage.getItem("userData"))
      useEffect(() => {
        const getlocalstorage = localStorage.getItem("comparestones")
        if(getlocalstorage){
            let comparestones = JSON.parse(getlocalstorage)
            localStorage.setItem("comparestones",JSON.stringify(comparestones))
            const finalData = {
              "stocknumber":comparestones.toString()
            }
            console.log(finalData,"finalDatafinalData")
            axios({
              method: "GET",
              url: `${NODE_API_URL}/EUR/fetchDiamondsByStockNumber`,
              headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
              params: finalData,
            })
            .then(function(res) {
                setLoader(false)
              setCompareData(res.data.data)
            })
            .
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
        }

      },[update])
      useEffect(() => {
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        selected.map(value => {
            const finddata = comparedata.find(element => element.stockId.toString() === value.toString())
            if(finddata){
                temptotalcarat += finddata.carat
                temptotalinrcar += (selected.length>0?finddata.ourRate/selected.length:0)
                temptotalourprice += finddata.ourPrice
            }
        })
        setTotalCarat(temptotalcarat)
        setTotalInrCar(selected.length>0?(temptotalourprice/temptotalcarat * props.conversionrate):0)
        setTotalOurPrice(temptotalourprice * props.conversionrate)
    },[selected])
    const shadeMappings = {
        "none": { label: "No.SH", tooltip: "Shade: NONE" },
        "brown": { label: "BR", tooltip: "Shade: Brown" },
        "light brown": { label: "L.BR", tooltip: "Shade: Light Brown" },
        "green": { label: "GR", tooltip: "Shade: Green" },
        "light green": { label: "L.GR", tooltip: "Shade: Light Green" },
        "grey": { label: "GREY", tooltip: "Shade: Grey" },
        "black": { label: "BLK", tooltip: "Shade: Black" },
        "pink": { label: "PINK", tooltip: "Shade: Pink" },
        "blue": { label: "BLUE", tooltip: "Shade: Blue" },
        "mix tinge": { label: "MIX", tooltip: "Shade: Mix Tinge" },
    };
    
    const renderShadeLabel = (shade) => {
        const mapping = shadeMappings[shade ? shade.toLowerCase() : ""];
        if (!mapping) return null;
    
        return (
            <span>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">{mapping.tooltip}</Tooltip>}
                >
                    <span className={`label label-light-primary label-light-shade_${shade.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{mapping.label}</span>
                </OverlayTrigger>
            </span>
        );
    };
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
      const BuyDiamond = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        // console.log(selected,"selected")
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
       const selectedvalues = selected.map(value => {
            const finddata = comparedata.find(val => value.toString() === val.stockId.toString())
            // if(finddata.location === "INDIA"){
                diamondcount += 1
                temptotalcarat += finddata.carat
                temptotalourprice += finddata.ourPrice
                temptotalinrcar += (diamondcount>0?finddata.ourRate/diamondcount:0)
            // }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        // console.log(temptotalinrcar,"temptotalinrcar")
        setDiamondSelected(selectedvalues)
        setBuyPopUp(true)
        setBuyPopUpInr(temptotalcarat>0?temptotalourprice/temptotalcarat:0)
        setDiamondcount(diamondcount)
        setBuyPopUpTotal(temptotalourprice)
        // console.log(selectedvalues,"selectedvalues")
      }
      const HoldDiamond = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        // console.log(selected,"selected")
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
       const selectedvalues = selected.map(value => {
            const finddata = comparedata.find(val => value.toString() === val.stockId.toString())
            if(finddata.can_hold === 1){
                diamondcount += 1
                temptotalcarat += finddata.carat
                temptotalourprice += finddata.ourPrice
                temptotalinrcar += (diamondcount>0?finddata.ourRate/diamondcount:0)
            }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        // console.log(temptotalinrcar,"temptotalinrcar")
        setDiamondSelected(selectedvalues)
        setHoldPopUp(true)
        setBuyPopUpInr(temptotalcarat>0?temptotalourprice/temptotalcarat:0)
        setDiamondcount(diamondcount)
        setBuyPopUpTotal(temptotalourprice)
        // console.log(selectedvalues,"selectedvalues")
      }  
      console.log(comparedata,"comparedatacomparedata")
      const RemoveStone = (data) => {
        console.log(data,"REMOVESTONES")
        OpenSwal()
        const getlocalstorage = localStorage.getItem("comparestones")
        if(getlocalstorage){
            let stones = JSON.parse(getlocalstorage).filter(val => val !== data.stockId.toString())
            stones = stones.filter(val => val !== data.certificate.toString())
            localStorage.setItem("comparestones",JSON.stringify(stones))
            let newselected = selected.filter(diamond => diamond.toString() !== data.stockId.toString()) 
            setSelected(newselected)
            setUpdate(!update)
            setSwalWarnOpen(false)
            setRemoveData(null)
        }
      }
      const OpenSwal = (data) => {
        setSwalWarnOpen(true)
        setAlertMsg("are you sure you want to remove this stone?")
        setRemoveData(data)
      }
      const CloseSwal = () => {
        setSwalWarnOpen(false)
        setRemoveData(null)
      }
      const [mainimage,setMainImage] = useState()
      const [mainshape,setMainShape] = useState()
      const MainImageLoad = (diamonddata) => {
        console.log(diamonddata,"diamonddata")
        if(diamonddata.aws_image){
            setMainImageClick(true)
            setMainImage(diamonddata.aws_image)
            setMainShape(diamonddata.shape)
        }else{
            setMainImageClick(true)
            setMainImage(`/media/shape/${diamonddata.shape.toLowerCase()}-no.png`)
            setMainShape(diamonddata.shape)
        }
      }
      const GenerateTitle = (row) => {
        let title = ""
        // if(row.Lab){
        //     title += row.Lab
        // }
        if(row.shape){
            title += " "
            title += row.shape
        }
        if(row.carat){
            title += " "
            title += row.carat.toFixed(2) 
        }
        // if(row.f_intensity){
        //     title += " "
        //     title += row.f_intensity
        // }
        if(row.color){
            title += " "
            title += row.color
        }
        if(row.clarity){
            title += " "
            title += row.clarity
        }

        if(row.cut){
            title += " "
            title += row.cut
        }
       
        if(row.polish){
            title += " "
            title += row.polish
        }

        if(row.symmetry){
            title += " "
            title += row.symmetry
        }

        if(row.fluorescence){
            title += " "
            title += row.fluorescence
        }
        console.log(title.length)
        return title.length > 36 ? `${title.substring(0, 40)}...`: title
        // return title
    }
    const deselectAll = () => {
        setSelected([])
      };
    return (
        <>
            <ConfirmGoods show={buypopup} onHide={() => setBuyPopUp(false)} diamondselected={diamondselected} symbol={props.symbol} conversionrate={props.conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={props.language} userinfo={props.userinfo}/>  
            <HoldPopup show={holdpopup} onHide={() => setHoldPopUp(false)} diamondselected={diamondselected} symbol={props.symbol} conversionrate={props.conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={props.language} userinfo={props.userinfo}/>  

            {loader?<CompareSkeleton/>:<div className="card card-custom card-sticky gutter-b">
                {selected.length>0?
                    <>
                        <div className="card-header custom-scrollbar flex-nowrap text-nowrap selecetedHeader" style={{minHeight:"50px"}}>
                            <div className="d-flex justify-content-center align-items-center natTab">
                                <button type="button" className="btn btn-primary mr-2 px-8 text-nowrap"  onClick={() => BuyDiamond(selected) }> <BsLightningFill className='font-size-h5' />{props.language.SEARCHLIST_BUYDIAMONDBTN}</button>
                                <button type="button" className="btn btn-hover-light-dark mr-1 text-nowrap"  onClick={() => HoldDiamond(selected) }><SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/hold.svg")} height="18px" width="18px" /> {props.language.SEARCHLIST_HOLDDIAMONDBTN}</button>
                            </div>
                            <div className="card-toolbar flex-nowrap">
                                <span className="selected-diamond-box mx-2">
                                    <span className='cursor-pointer' onClick={deselectAll}><IoMdClose className='text-dark'/></span> {selected.length} {props.language.DIAMOND_COMPARISION_SELDIAMONDS} | {props.language.SEARCHLIST_CARAT} : {numberWithCommas(parseFloat(totalcarat))} | Amount : {props.symbol}{numberWithCommas(parseFloat(totalourprice))}
                                </span>
                                <span className="cursor-pointer btn btn-hover-light-dark mr-2"  onClick={() =>window.history.go(-1)}><BsArrowLeftShort /> Back </span>
                            </div>
                        </div>
                    </>
                :
                    <div className="card-header p-0" style={{minHeight:"50px"}}>
                        <div className="card-title align-items-center">
                            <div className="d-flex align-items-center">
                                {/* <span className="cursor-pointer btn btn-light-warning btn-sm mr-2 " onClick={()=>props.setShortlistSwitchByMail(false)}><BsArrowLeftShort /> Back</span> */}
                                <span className="card-label font-weight-bolder text-dark">  {props.language.DIAMOND_COMPARISION_DC} ({comparedata.length})
                                </span>
                            </div>
                        </div>
                        <div className="card-toolbar flex-md-nowrap">
                            <span className="cursor-pointer btn btn-hover-light-dark mr-2"   onClick={() =>window.history.go(-1)}><BsArrowLeftShort /> Back </span>
                        </div>
                    </div>
                }
                <div className="card-body">
                    <div className="row">
                        <div className="col-4 col-md-2">
                            <div className="row">
                                <div className="col-12" style={{opacity:'0'}}>
                                    <div className="row m-0 card rounded-0 grid_box gridData">
                                        <div className="col-12 p-0 d-flex align-items-center justify-content-center bg-gray-200 position-relative" style={{height:"220px"}}>
                                            <img className="img-fluid" alt="shape"  src={toAbsoluteUrl(`/media/shape/round-no.png`)} />
                                        </div>
                                        <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                                            <div>
                                                <div className=" d-inline-block cursor-pointer font-weight-bold" >N</div>
                                            </div>
                                        </div>
                                        <hr className="w-100 my-4" />
                                        <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                                            <div>
                                                <div className=" d-inline-block cursor-pointer font-weight-bold" >N</div>
                                            </div>
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex px-2">
                                            <div className="text-center">
                                                <SVG width="25px" height="25px" src={toAbsoluteUrl(`/media/shape/svg_shape/round.svg`)} />
                                                <span className="text-uppercase small">round</span >
                                            </div>
                                            <span className="text-dark font-weight-bold text-capitalize font-size-h5 flex-wrap d-flex ml-1"> 0.20 ROUND D IF EX EX EX NON NON NON </span>
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex justify-content-between ">
                                            <span className="text-muted font-size-xl font-weight-bold">$/CT: <span className=" font-size-xl">123</span ></span>
                                            <span className="text-muted font-size-xl font-weight-bold">TOTAL: <span className=" font-size-xl">123</span ></span>
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex justify-content-between align-items-center mb-4">
                                            <FaAward />
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 mb-5 px-2">
                                            <span>E</span>
                                            <span>S</span>
                                        </div>
                                    </div>
                                </div>
                          
                                <div className="col-12 mt-3 bg-gray-100 p-5 text-center  font-weight-bold font-size-lg">
                                    <span style={{opacity:'0'}}>D</span>
                                </div>
                                <div className="col-12 bg-gray-200 py-3">
                                    {/* <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_STOCKID}</div> */}
                                    <div className="font-weight-bold mt-3">{props.language.DIAMOND_COMPARISION_DIAMONDTYPE}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_SHAPE}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_CARAT}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_COLOR}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_CLARITY}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_CUT}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_POL}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_SYMM}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_FLOU}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_LAB}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_CERTIFICATE}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_LOCATION}</div>
                                    <div className="font-weight-bold mt-3">{props.language.DIAMOND_COMPARISION_DELIN}</div>
                                </div>
                                <div className="col-12 pt-3 bg-gray-100 p-5 text-center  font-weight-bold font-size-lg">
                                    <span style={{opacity:'0'}}>P</span>
                                </div>
                                <div className="col-12 bg-gray-200 py-3">
                                    <div className="font-weight-bold mt-3">{props.language.MUI_MEASUREMENT}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_TABLE}%</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_DEPTH}%</div>
                                    <div className="font-weight-bold mt-3">{props.language.DETAIL_CROWN}</div>
                                    <div className="font-weight-bold mt-3">{props.language.DETAIL_PAVILION}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_GIRDLE}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_GIRDLECONDTION}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_CULET}</div>
                                    <div className="font-weight-bold mt-3">{props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_FANCY_INTENSITY}</div>
                                    <div className="font-weight-bold mt-3">{props.language.DETAIL_OVERTONE}</div>
                                    <div className="font-weight-bold mt-3">{props.language.CARAT_FANCY} {props.language.SEARCHLIST_COLOR}</div>
                                </div>
                                <div className="col-12 pt-3 bg-gray-100 p-5 text-center  font-weight-bold font-size-lg">
                                    <span style={{opacity:'0'}}>A</span>
                                </div>
                                <div className="col-12 bg-gray-200 py-3">
                                    <div className="font-weight-bold mt-3">{props.language.MUI_SHADE}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_MILKY}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_EYECLEAN}</div>
                                    <div className="font-weight-bold mt-3">{props.language.DIAMOND_COMPARISION_AVIAIL}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_TREATMENT}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_KEY}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_BRAND}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_ORIGIN}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_GROWTHTYPE}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-8 col-md-10 custom-scrollbar">
                            <div className="row" style={{minWidth:'1500px'}}> 
                                { comparedata ? comparedata.slice(0, 4).map((data) =>(
                                    <div className={`compare-diamond-col-3 ${data.diamondType === "N" ? "natTab" : "labTab"}`} key={data.stockId}>
                                        <div className="row m-0 card bg-white mx-auto grid_box gridData">
                                            <div className="col-12 p-0 d-flex align-items-center justify-content-center bg-gray-200 position-relative ribbon ribbon-top ribbon-left" style={{height:"220px"}}>
                                                {data.diamondType === "N" || data.diamondType === "W" ? null: <div className="ribbon-target custom-top-ribbon lab-ribbon" style={{top:"-2px",right:"20px"}} >Lab</div> }
                    
                                                { (data.diamondType === "L" ) && (data.growthType && data.growthType === "HPHT")  ? 
                                                <div class="ribbon-target bg-light-success-solid-40 custom-left-ribbon" style={{top :"180px",left:"-3px"}}>
                                                    HPHT
                                                </div> : null }
                                                { (data.diamondType === "L" ) && (data.growthType && data.growthType === "CVD")  ? 
                                                <div class="ribbon-target bg-light-success-solid-40 custom-left-ribbon" style={{top :"180px",left:"-3px"}}>
                                                    CVD
                                                </div> : null }

                                                <div className="gridCheck">
                                                    <Checkbox
                                                        checked={isSelected(data.stockId)}
                                                        className=""
                                                        inputProps={{ 'aria-labelledby': props.labelId }}
                                                        onClick={(event) => handleClick(event, data.stockId)}
                                                        icon={<BsCircle />} checkedIcon={<BsCheckCircleFill />}
                                                    />
                                                </div>
                                                <button type="button" className="close gridClose btn btn-light btn- btn-hover- font-weight-bolder font-size-h3" onClick={() => OpenSwal(data)}>
                                                    <span aria-hidden="true"><IoCloseSharp /></span>
                                                    <span className="sr-only">Close</span>
                                                </button>
                                                <SwalWarn show={swalWarnOpen} message={alertMsg} onClose={() => setSwalWarnOpen(false)} responseOnOk={() => RemoveStone(removedata)} onHide={() => CloseSwal()}/> 

                                                <LazyImageComponent data={data}/>
                                            </div>
                                            <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                                                <div>
                                                    <div className="d-inline-block cursor-pointer" >{data.diamondType + "-" + data.stockId}</div>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {
                                                                data.availability === 'Memo' || data.availability === 'Hold'
                                                                    ? `Business Process`
                                                                    : data.availability === "Guaranteed"
                                                                    ? `Guaranteed`
                                                                    : data.availability === "Not Available"
                                                                    ? `Not Available`
                                                                    : data.availability === "Subject to Prior sale"
                                                                    ? `Subject to Prior sale`
                                                                    : `Unknown`
                                                                }
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <img
                                                            className="availIco mx-2"
                                                            alt={data.availability}
                                                            src={toAbsoluteUrl(`/media/svg/${
                                                                data.availability === 'Memo' || data.availability === 'Hold'
                                                                    ? `availability_primary.svg`
                                                                    : data.availability === "Guaranteed"
                                                                    ? `availability_success.svg`
                                                                    : data.availability === "Not Available"
                                                                    ? `availability_danger.svg`
                                                                    : data.availability === "Subject to Prior sale"
                                                                    ? `availability_warning.svg`
                                                                    : `availability_blank_white.svg`
                                                            }`)}
                                                        />
                                                        
                                                    </OverlayTrigger>
                                                </div>
                                                <div >
                                                        <>
                                                            {data.lab}:<CertificateLink Certificate_link={data.Certi_link} lab={data.lab} certificate={data.certificate} hideLabName className={`mx-2 text-left text-dark`} />
                                                            <CopyUrlAlert text={data.certificate}/>
                                                        </>
                                                    
                                                </div>
                                            </div>
                                            <hr className="w-100 my-2" />
                                            <div className="col-12 d-flex justify-content-between align-items-center">
                                                <div className="d-flex advTag justify-content-end align-items-center">
                                                    {/* shade */}
                                                    {renderShadeLabel(props.shade)}
                                                    
                                                    {data.milky &&data.milky.toLowerCase() === "no milky" ?
                                                        <span>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        NO MILKY
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="label font-weight-bold label-light-no_milky label-pill label-inline label-sm  mr-1 px-2">NO.ML</span>
                                                            </OverlayTrigger>
                                                        </span>
                                                    :
                                                        null
                                                    }

                                                    {data.milky &&data.milky.toLowerCase() === "luster low" ?
                                                        <span>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        Luster Low
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="label font-weight-bold label-light-luster_low label-pill label-inline label-sm  mr-1 px-2">LL</span>
                                                            </OverlayTrigger>
                                                        </span>
                                                    :
                                                        null
                                                    }

                                                    {data.milky &&data.milky.toLowerCase() === "light milky" ?
                                                        <span>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        LIGHT MILKY
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="label font-weight-bold label-light-light_milky label-pill label-inline label-sm  mr-1 px-2">L.ML</span>
                                                            </OverlayTrigger>
                                                        </span>
                                                    :
                                                        null
                                                    }

                                                    {data.milky &&data.milky.toLowerCase() === "milky" ?
                                                        <span>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        MILKY
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="label font-weight-bold label-light-milky label-pill label-inline label-sm  mr-1 px-2">H.ML</span>
                                                            </OverlayTrigger>
                                                        </span>
                                                    :
                                                        null
                                                    }

                                                    {/* eyeclean */}
                                                    {data.eyeClean &&data.eyeClean.toLowerCase() === "yes" ?
                                                        <span>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        100% Eye Clean
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="label font-weight-bold label-light-info label-pill label-inline label-sm  mr-1 px-2">100% EC</span>
                                                            </OverlayTrigger>
                                                        </span>
                                                    :
                                                        null
                                                    }
                                                    {data.eyeClean &&data.eyeClean.toLowerCase() === "no" ?
                                                        <span>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        No Eye Clean
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="label font-weight-bold label-light-danger label-pill label-inline label-sm  mr-1 px-2">EC: NO</span>
                                                            </OverlayTrigger>
                                                        </span>
                                                    :
                                                        null
                                                    }

                                                    { (data.diamondType === "L" ) && (data.treatment &&data.treatment.toLowerCase() === "as grown") ? 
                                                        <span>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        As Grown
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="label font-weight-bold label-light-success label-pill label-inline label-sm  px-2">As-G</span>
                                                            </OverlayTrigger>
                                                        </span>
                                                    : 
                                                        null
                                                    }
                                                    {/* natural treated */}
                                                    { (data.diamondType === "N" ||data.diamondType === "W") && (data.treatment &&data.treatment.toLowerCase() !== "none")  ? 
                                                        <span>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        Treated
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="label font-weight-bold label-light-danger label-pill label-inline label-sm  px-2">Tx</span>
                                                            </OverlayTrigger>
                                                        </span>
                                                    : 
                                                        null
                                                    }
                                                </div>
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <ImagePopupCommon data={data}/>
                                                    <VideoPopupCommon data={data} userinfo={userData}/>
                                                    {data.certificate  && (
                                                        <span>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">Certificate</Tooltip>
                                                                }
                                                            >
                                                                
                                                                <CertificateLink Certificate_link={data.Certi_link} lab={data.lab} certificate={data.certificate} useIcon hideLabName className={`mx-2 text-primary text-left`} />
                                                            </OverlayTrigger>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <hr className="w-100 my-2" />
                                            <div className="col-12 d-flex align-items-center  px-2">
                                                <div className="text-center">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {data.shape}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <SVG width="25px" height="25px" src={toAbsoluteUrl(`/media/shape/svg_shape/${data.shape.toLowerCase()}.svg`)} />
                                                    </OverlayTrigger>
                                                    <div className="text-uppercase small">{data.shape}</div >
                                                </div>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            {data.carat.toFixed(2)} {data.shape}{" "}
                                                            {data.color}{" "}
                                                            {data.clarity} {data.cut}{" "}
                                                            {data.polish} {data.symmetry} {data.fluorescence}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="text-dark  font-weight-bold text-capitalize font-size-h5 flex-wrap grid_title ml-1">
                                                        {GenerateTitle(data)}
                                                    </span>
                                                </OverlayTrigger>
                                            </div>
                                            <hr className="w-100 my-2" />
                                            <div className="col-12 d-flex justify-content-between align-items-end">
                                                <div className="d-flex flex-column">
                                                    <div className="small" >{data.ourDiscount ? <span className={`${(data.ourDiscount >= 0) ? 'text-success' : 'text-danger'}`}>{data.ourDiscount}%</span> : <span className={`text-success`}>0.00%</span>}</div>
                                                    {
                                                        props.symbol !=="$" ? <>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        ${numberWithCommas(data.ourRate)}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="text-muted small">{props.symbol}/CT: <span className="text-dark font-size-xl">{props.symbol}{numberWithCommas(data.ourRate * props.conversionrate)}</span ></span>
                                                            </OverlayTrigger>
                                                        </> :
                                                        <span className="text-muted small">{props.symbol}/CT: <span className="text-dark font-size-xl">{props.symbol}{numberWithCommas(data.ourRate * props.conversionrate)}</span ></span>
                                                    }
                                                </div>
                                                <div className="grid-total-title">
                                                    <span className="text-dark small">{props.symbolname}</span>
                                                    {
                                                        props.symbol !=="$" ? <>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        ${numberWithCommas(data.ourPrice)}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="font-size-h3">{props.symbol}{numberWithCommas(data.ourPrice* props.conversionrate)}</span >
                                                            </OverlayTrigger>
                                                        </> :
                                                        <span className="font-size-h3">{props.symbol}{numberWithCommas(data.ourPrice* props.conversionrate)}</span >
                                                    } 
                                                </div>
                                            </div>
                                            <hr className="w-100 my-2" />
                                            <div className="col-12 d-flex justify-content-between px-2 mtdr">
                                                <span className="text-muted font-size-sm">M: <span className="text-dark font-size-sm">{data.length?.toFixed(2)}*{data.width?.toFixed(2)}*{data.height?.toFixed(2)}</span ></span>
                                                <span className="text-muted font-size-sm">T: <span className="text-dark font-size-sm">{data.tablePercent?.toFixed(2)}%</span ></span>
                                                <span className="text-muted font-size-sm">D: <span className="text-dark font-size-sm">{data.depthPercent?.toFixed(2)}%</span ></span>
                                                {data.ratio ? <span className="text-muted font-size-sm">R: <span className="text-dark font-size-sm">{data.ratio?data.ratio.toFixed(2):"-"}</span ></span> : ""}
                                            </div>
                                            <hr className="w-100 my-2" />
                                            <div className="col-12 mb-3 px-2">
                                                <span className="d-flex text-muted justify-content-between align-items-center font-size-sm">
                                                    <div>
                                                        <span className="text-dark-25 mr-2"><FaCalendarAlt /></span> 
                                                        {props.language.SEARCHGRID_SHIPPINGDAYS}: <span className="font-size-sm ml-1 text-dark ">{<>{data.shippingDays}{" "}{props.language.MUI_DAYS} </>}</span>
                                                    </div>
                                                    {data.location ? (
                                                        <OverlayTrigger
                                                            placement="top"
                                                            className="text-capitalize"
                                                            overlay={
                                                                <Tooltip id="tooltip">{data.location}</Tooltip>
                                                            }
                                                        >
                                                            <img
                                                                className="flagIco mr-1"
                                                                alt={data.value}
                                                                src={toAbsoluteUrl(
                                                                    `/media/map/${data.location.toLowerCase()}.png`
                                                                )}
                                                                onError={e => {
                                                                    e.currentTarget.src =toAbsoluteUrl(
                                                                        `/media/map/other.png`
                                                                        )
                                                                    }}
                                                            />
                                                        </OverlayTrigger>
                                                    ) : (
                                                        null
                                                    )}
                                                </span>
                                                {!data.supplierName ? (
                                                    ""
                                                ):(
                                                    <>
                                                    <OverlayTrigger
                                                            placement="top"
                                                            className="text-capitalize"
                                                            overlay={<Tooltip id="tooltip">{data.supplierName}</Tooltip>}
                                                        >
                                                            <span className="d-flex text-muted align-items-center font-size-sm mt-2" >
                                                                <span className="text-dark-25 mr-2">
                                                                    <FaUserCircle className="mr-2"/> 
                                                                    {props.language.MUI_SUPPLIER}: 
                                                                    <span className="font-size-sm ml-1 text-dark text-truncate">{data.supplierName}</span>
                                                                </span>
                                                            </span>
                                                        </OverlayTrigger>
                                                         </> 
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* <div className="col-12">
                                                <div className=" d-flex align-items-center justify-content-between border px-3">
                                                    <label className="checkbox my-2">
                                                        <input 
                                                        type='checkbox'
                                                        checked={isSelected(data.stockId)}
                                                        className=""
                                                        onClick={(event) => handleClick(event, data.stockId)}
                                                         />
                                                        <span className="mr-2"></span>
                                                        {props.language.DIAMOND_COMPARISION_SELECTBUY}
                                                    </label> 
                                                    
                                                </div>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <div className="text-center p-0">
                                                    {data.aws_image &&  data.aws_image !=="null" ? (
                                                        <img
                                                            className="img-fluid compareDiam mr-2"
                                                            alt={data.shape}
                                                            src={data.aws_image}
                                                            style={{ backgroundColor: "#f4f4f4"}}
                                                        />
                                                    ) : (
                                                        <img
                                                            className="img-fluid compareDiam mr-2"
                                                            alt={data.shape}
                                                            src={toAbsoluteUrl(
                                                                `/media/shape/${data.shape.toLowerCase()}-no.png`
                                                            )}
                                                            height="130px"
                                                            style={{ backgroundColor: "#f4f4f4"}}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12 mt-3 text-center">
                                                <div className="text-muted small font-weight-bold"> $/CT ${data.ourRate.toFixed(2)} </div>
                                                <div className=" font-size-h3 font-weight-bold">${data.ourPrice.toFixed(2)} </div>
                                                <div className={`${(data.ourDiscount >= 0) ? 'text-success' : 'text-danger'} small font-weight-bold`}>{data.ourDiscount}% </div>
                                            </div>*/}
                                            <div className="col-12 mt-3 bg-gray-100 p-5 text-center  font-weight-bold font-size-lg">
                                                {props.language.DETAIL_DIAMONDDETAILS}
                                            </div> 
                                            <div className="col-12 py-3 text-center">
                                                {/* <div className="mt-3">{data.diamondType}-{data.stockId}</div> */}
                                                <div className="mt-3 ">{data.diamondType === "L" ? "Lab Grown Diamond" : "Natural Diamond"}</div>
                                                <div className="mt-3 text-capitalize">{data.shape?data.shape:"-"}</div>
                                                <div className="mt-3">{data.carat?data.carat.toFixed(2):"-"}</div>
                                                <div className="mt-3">{data.color?data.color:"-"}</div>
                                                <div className="mt-3">{data.clarity?data.clarity:"-"}</div>
                                                <div className="mt-3">{data.cut?data.cut:"-"}</div>
                                                <div className="mt-3">{data.polish?data.polish:"-"}</div>
                                                <div className="mt-3">{data.symmetry?data.symmetry:"-"}</div>
                                                <div className="mt-3">{data.fluorescence?data.fluorescence:"-"}</div>
                                                <div className="mt-3">{data.lab?data.lab:"-"}</div>
                                                <div className="mt-3">
                                                   
                                                    <CertificateLink Certificate_link={data.Certi_link} lab={data.lab} certificate={data.certificate} hideLabName className={` `} />
                                                    <CopyUrlAlert text={data.certificate}/>
                                                </div>
                                                <div className="mt-3">{data.location ? data.location : "-"}</div>
                                                <div className="mt-3">{data.shippingDays?data.shippingDays:"-"} days</div>
                                            </div>
                                            <div className="col-12 pt-3 bg-gray-100 p-5 text-center  font-weight-bold font-size-lg">
                                                {props.language.MUI_PARAM_DETAILS}
                                            </div>
                                            <div className="col-12 py-3 text-center">
                                                <div className="mt-3">{data.length?.toFixed(2)?data.length?.toFixed(2):"0.00"}*{data.width?.toFixed(2)?data.width?.toFixed(2):"0.00"}*{data.height?.toFixed(2)?data.height?.toFixed(2):"0.00"}</div>
                                                <div className="mt-3">{data.tablePercent?data.tablePercent:"0.00"}%</div>
                                                <div className="mt-3">{data.depthPercent?data.depthPercent:"0.00"}%</div>
                                                <div className="mt-3">{data.crownHeight ? <>{data.crownHeight}%</> : "-"}, {data.Crn_Ag ? <>{data.Crn_Ag}º</> : "-"}</div>
                                                <div className="mt-3">{data.crownAngle ? <>{data.crownAngle}%</> : "-"}, {data.Pav_Ag ? <>{data.Pav_Ag}º</> : "-"}</div>
                                                <div className="mt-3">{data.girdle ? data.girdle : "-"}</div>
                                                <div className="mt-3">{data.girdleCondition ? data.girdleCondition : "-"}</div>
                                                <div className="mt-3">{data.culet ? data.culet : "-"}</div>
                                                <div className="mt-3">{data.fancy_intensity ? data.fancy_intensity : "-"}</div>
                                                <div className="mt-3">{data.fancy_overtone ? data.fancy_overtone : "-"}</div>
                                                <div className="mt-3">{data.fancy_color ? data.fancy_color : "-"}</div>

                                            </div>
                                            <div className="col-12 pt-3 bg-gray-100 p-5 text-center  font-weight-bold font-size-lg">
                                                {props.language.MUI_ADDITIONALDETAILS}
                                            </div>
                                            <div className="col-12 py-3 text-center">
                                                <div className="mt-3">{data.shade ? data.shade : "-"}</div>
                                                <div className="mt-3">{data.milky ? data.milky : "-"}</div>
                                                <div className="mt-3">{data.eyeClean ? data.eyeClean : "-"}</div>
                                                <div className="mt-3">
                                                    {data.availability === "Memo" ||
                                                        data.availability === "Hold"
                                                            ? `Business Process`
                                                            : data.availability === "Guaranteed"
                                                            ? `Guaranteed`
                                                            : data.availability === "Not Available"
                                                            ? `Not Available`
                                                            : data.availability === "Subject to Prior sale"
                                                            ? `Subject to Prior sale`
                                                            : `Unknown`}
                                                </div>
                                                {/* <div className="mt-3">{data.treatment ? data.treatment : "-"}</div> */}
                                                <div className="mt-3">{data.diamondType === "L" ?data.treatment?data.treatment === "Treated"?"Post Growth Treatment":data.treatment:data.treatment?data.treatment:"-":data.treatment?data.treatment:"-"}</div>
                                                <div className="mt-3 text-truncate mx-auto"  style={{maxWidth:"110px"}}>
                                                    {data.keyToSymbol ?
                                                        <>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        {data.keyToSymbol}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span> {data.keyToSymbol}</span>
                                                            </OverlayTrigger> 
                                                        </>
                                                    : "-"}
                                                </div>
                                                <div className="mt-3">{data.brand ? data.brand : "-"}</div>
                                                <div className="mt-3">{data.diamondType === "N" && data.origin ? data.origin : "-"}</div>
                                                <div className="mt-3">{data.diamondType === "L" && data.growthType ? data.growthType : "-"}</div>
                                        
                                            </div>
                                        </div>
                                    </div>
                                )) : null}
                                {comparedata.length === 0?<>
                                <div className="col-3 compareEmptyBox text-center" key={0}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                <div className="col-3 compareEmptyBox" key={1}>
                                   <div> <button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                <div className="col-3 compareEmptyBox" key={2}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                <div className="col-3 compareEmptyBox" key={3}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                </>:""}
                                {comparedata.length === 1?<>
                                <div className="col-3 compareEmptyBox" key={1}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                <div className="col-3 compareEmptyBox" key={2}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                <div className="col-3 compareEmptyBox" key={3}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                </>:""}
                                {comparedata.length === 2?<>
                                <div className="col-3 compareEmptyBox" key={2}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                <div className="col-3 compareEmptyBox" key={3}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                </>:""}
                                {comparedata.length === 3?<>
                                <div className="col-3 compareEmptyBox" key={3}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                </>:""}
                                <AddCompareDiamond conversionrate={props.conversionrate} symbolname={props.symbolname} show={showcompare} close={() => setShowCompare(false)} onHide={() => setShowCompare(false)} setUpdate={setUpdate} update={update}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
};
