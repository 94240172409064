/* eslint-disable jsx-a11y/anchor-is-valid */
import React ,{useEffect,useState} from "react";
import { Tab , Nav } from "react-bootstrap";
import {Security} from "./Security"
import { TimeZone } from "./TimeZone";
// import Ccmodesetting from "./Ccmode/Ccmodesetting"
// import IframeSetting from "./Iframe/IframeSetting"
import {ApiSettings} from "./ApiSettings/ApiSettings"
import axios from "axios";
import { NODE_API_URL,BASE_URL } from "../../../env_config";
import Cookies from "js-cookie"
import { useLocation } from "react-router-dom";
export const Settings = (props) => {
    const [ccmodewhitelabeldata,setCCModeWhiteLabelData] = useState()
    const [customeriframe,setCustomerIframe] = useState()
    const [updatekey,setUpdateKey] = useState()
    const [key, setKey] = useState('security');
    const location = useLocation()
    const [feedsetting,setFeedSetting] = useState(null)
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"))

        const finalData = {
            "user_id":userData.CustomerId
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/EUR/fetchCCModeWhiteLable`,
            headers: { "Content-Type": "application/json" },
            data: {
                CustomerId:finalData.user_id
            },
        }).then(res => {
            if(res && res.data && res.data.data){
                // console.log(res.data.data,"CCMODEEEEWHITELABEL")
                setCCModeWhiteLabelData(res.data.data)
            }
        })
        if(location.state){
            setKey(location.state)
        }
    },[])
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"))
        axios({
            method: "POST",
            url: `${NODE_API_URL}/EUR/fetchIframeData`,
            headers: { "Content-Type": "application/json" },
            data: {
                "user_id":userData.CustomerId,
                "api_id":props.userinfo.api_id
            },
        }).then(supreq => {
            if(supreq && supreq.data && supreq.data.data){
                if(supreq.data.data !== "Record Not Found!"){
                setCustomerIframe(supreq.data.data)

                }
            }
        })
    },[updatekey])
    useEffect(() => {
        if(props.userinfo){
            axios({
                method: "get",
                url: `${NODE_API_URL}/EUR/fetch-iframeorapi`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                }
            }).then(supreq => {
                if(supreq && supreq.data && supreq.data.data){
                    // setFeedLoader(false)
                    setFeedSetting(supreq.data.data)
                }
            }).
            catch(error => {
              if(error?.response?.data?.message === "UnAuthorized!"){
                var cookies = document.cookie.split(";");
              for (var i = 0; i < cookies.length; i++) {
                if(cookies[i] && !cookies[i].includes("view")){
                  var equals = cookies[i].indexOf("=");
                  var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
              }
              
              window.location.replace(`${BASE_URL}/logout`);
            }
            })
        }
    },[props.userinfo])
    // console.log(customeriframe,"customeriframesetting")
    return (
        <div className="container-fluid">
            <Tab.Container id="settingTab" activeKey={key} onSelect={(k) => setKey(k)}>
                {props.userinfo.v_customer === "12"?<div className="row">
                    <div className="col-md-3">
                        <div className="card border-0 custom-card gutter-b rounded-0">
                            <div className="card-body p-3">
                                <Nav
                                    variant="pills"
                                    className="flex-column custom_nav"
                                >
                                    <Nav.Item className="border-bottom">
                                        <Nav.Link className="flex-wrap" eventKey="security">
                                           <span className="font-size-h5 px-0 col-12">{props.language.SETTING_SECURITYANDLOGIN}</span >
                                           <span className="text-muted small px-0 col-12">{props.language.SETTING_UPDATE_YOUR_PASSWORD}</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9 bg-white">
                        <div className="card custom-card rounded-0">
                            <div className="card-body">
                                <Tab.Content>
                                    <Tab.Pane eventKey="mobile">
                                        1
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="security">
                                        <Security language={props.language}/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="notifications">
                                        3
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="permissions">
                                        4
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="apisetting">
                                        {feedsetting && feedsetting.api === 1 && feedsetting.feed_type === "API"?<ApiSettings userinfo={props.userinfo} language={props.language} feedsetting={feedsetting}/>:""}
                                    </Tab.Pane>
                                    {/* <Tab.Pane eventKey="pluginsetting">
                                       { customeriframe && props.userinfo.api === 1 && props.userinfo.feed_type === "IFrame Plug-in"?<IframeSetting userinfo={props.userinfo} customeriframe={customeriframe} setUpdateKey={setUpdateKey} language={props.language}/>:""}
                                    </Tab.Pane> */}
                                    {/* {ccmodewhitelabeldata?
                                        <Tab.Pane eventKey="ccmodesetting">
                                            <Ccmodesetting userinfo={props.userinfo} language={props.language}/>
                                        </Tab.Pane>
                                    :""} */}
                                </Tab.Content>
                            </div>
                        </div>
                    </div>
                </div>:<div className="row">
                    <div className="col-md-3">
                        <div className="card custom-card gutter-b border-0 rounded-0">
                            <div className="card-body p-3">
                                <Nav
                                    variant="pills"
                                    className="flex-column custom_nav"
                                >
                                    {/* <Nav.Item className="border-bottom">
                                        <Nav.Link className="flex-wrap" eventKey="mobile">
                                           <span className="font-size-h5 px-0 col-12"> Mobile Setting</span >
                                           <span className="text-muted small px-0 col-12">update mobile setting</span>
                                        </Nav.Link>
                                    </Nav.Item> */}
                                    <Nav.Item className="border-bottom">
                                        <Nav.Link className="flex-wrap" eventKey="security">
                                           <span className="font-size-h5 px-0 col-12">{props.language.SETTING_SECURITYANDLOGIN}</span >
                                           <span className="text-muted small px-0 col-12">{props.language.SETTING_UPDATE_YOUR_PASSWORD}</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item className="border-bottom">
                                        <Nav.Link className="flex-wrap" eventKey="notifications">
                                           <span className="font-size-h5 px-0 col-12">Notifications</span >
                                           <span className="text-muted small px-0 col-12">Create or Update or Delete User by roles</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="border-bottom">
                                        <Nav.Link className="flex-wrap"eventKey="permissions">
                                           <span className="font-size-h5 px-0 col-12">Users & Permissions</span >
                                           <span className="text-muted small px-0 col-12">Generate API KEY and use feed to list Diamonds</span>
                                        </Nav.Link>
                                    </Nav.Item>*/}
                                    {feedsetting && feedsetting.api === 1 && feedsetting.feed_type === "API"?<Nav.Item className="border-bottom">
                                        <Nav.Link className="flex-wrap" eventKey="apisetting">
                                           <span className="font-size-h5 px-0 col-12">{props.language.SETTING_API_SETTING}</span >
                                           <span className="text-muted small px-0 col-12">{props.language.SETTING_API_SETTINGS}</span>
                                        </Nav.Link>
                                    </Nav.Item>:""}
                                    {/* {customeriframe && props.userinfo.api === 1 && props.userinfo.feed_type === "IFrame Plug-in"?<Nav.Item className="border-bottom"> 
                                        <Nav.Link className="flex-wrap" eventKey="pluginsetting">
                                           <span className="font-size-h5 px-0 col-12">{props.language.SETTING_REQUEST_IFRAME}</span >
                                           <span className="text-muted small px-0 col-12">{props.language.SETTING_REQUEST_IFRAMEMSG1}</span>
                                        </Nav.Link>
                                    </Nav.Item>:""}
                                    {ccmodewhitelabeldata ?
                                        <Nav.Item className="border-bottom">
                                            <Nav.Link className="flex-wrap" eventKey="ccmodesetting">
                                                <span className="font-size-h5 px-0 col-12">{props.language.SETTING_CCMODE}</span >
                                                <span className="text-muted small px-0 col-12">{props.language.SETTING_CCMODE_MSG1}</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    :"" } */}
                                     <Nav.Item >
                                        <Nav.Link className="flex-wrap" eventKey="timezonesetting">
                                            <span className="font-size-h5 px-0 col-12">Timezone</span >
                                            <span className="text-muted small px-0 col-12">Change your Timezone</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9 bg-white rounded p-8"style={{boxShadow:"rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px"}}>
                        <div className="card custom-card rounded-0">
                            <div className="card-body">
                                <Tab.Content>
                                    <Tab.Pane eventKey="mobile">
                                        1
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="security">
                                        <Security language={props.language}/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="notifications">
                                        3
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="permissions">
                                        4
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="apisetting">
                                        {feedsetting && feedsetting.api === 1 && feedsetting.feed_type === "API"?<ApiSettings userinfo={props.userinfo} language={props.language} feedsetting={feedsetting}/>:""}
                                    </Tab.Pane>
                                    {/* <Tab.Pane eventKey="pluginsetting">
                                       { customeriframe && props.userinfo.api === 1 && props.userinfo.feed_type === "IFrame Plug-in"?<IframeSetting userinfo={props.userinfo} customeriframe={customeriframe} setUpdateKey={setUpdateKey} language={props.language}/>:""}
                                    </Tab.Pane>
                                    {ccmodewhitelabeldata?
                                        <Tab.Pane eventKey="ccmodesetting">
                                            <Ccmodesetting userinfo={props.userinfo} language={props.language}/>
                                        </Tab.Pane>
                                    :""} */}
                                    <Tab.Pane eventKey="timezonesetting">
                                        <TimeZone userinfo={props.userinfo} language={props.language}/>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </div>
                </div>}
            </Tab.Container>
        </div>
    );
};
