import React, { useState ,useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { BsArrowLeftShort } from 'react-icons/bs';
import { Formik, Form } from 'formik';
import axios from "axios"
import ThemeFrom from './CcmodeSetupNew/FormEdit/ThemeFrom';
// import ReviewOrder from './ReviewOrder';
// import FormSuccess from '../../ccmode/CcmodeSetting/FormSuccess/FormSuccess';
import { NODE_API_URL,BASE_URL } from '../../../env_config';
import Cookies from "js-cookie"
import validationSchemaEditTheme from './CcmodeSetupNew/FormModel/validationSchemaEditTheme';
import kycFormModel from './CcmodeSetupNew/FormModel/kycFormModel';
import useStyles from './CcmodeSetupNew/styles';
import { Alert } from 'react-bootstrap';

const steps = [];
const { formId, formField } = kycFormModel;


export default function IFrameThemeSetting(props) {
  const classes = useStyles();
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchemaEditTheme[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  // const [companylogo,setCompanyLogo] = useState()
  const [initialValues,setInitialValues] = useState({})
  const [openpopup,setOpenPopUp] = useState(false)
  useEffect(() => {
    // fetchData(props.userinfo.mail).then(values => {
    //   // console.log(values,"fetchData")
    //   setInitialValues(values)
    // })
  },[])
  // console.log(initialValues,"initialValues")
  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    // console.log("Submit")
  }

  function _handleSubmit(values, actions) {
    actions.setSubmitting(false);
    let FinalData = {
      user_id:props.userinfo.id,
      data:[]
    }
    if(values.theme){
      FinalData.data.push({ifm_theme:values.theme})
    }
    if(values.wl_primarycolor){
      FinalData.data.push({ifm_NaturalPrimaryColor:values.wl_primarycolor})
    }
    if(values.n_fontcolor){
      FinalData.data.push({ifm_NaturalFontColor:values.n_fontcolor})
    }
    if(values.lab_primary){
      FinalData.data.push({ifm_LabPrimaryColor:values.lab_primary})
    }
    if(values.lab_fontcolor){
      FinalData.data.push({ifm_LabFontColor:values.lab_fontcolor})
    }
    if(values.multilanguage){
      FinalData.data.push({ifm_multilingual:values.multilanguage})
    }
    // if(values.timeZone){
    //   FinalData.data.push({time_zone:values.timeZone})
    // }


    axios({
      method: "POST",
      url: `${NODE_API_URL}/EUR/update-iframe`,
      headers: {
        "Content-Type": "application/json",
         "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
      },
      data: FinalData,
    }).then(res => {
        actions.setSubmitting(false);
        if(res.data.success){
          setOpenPopUp(true)
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
          setTimeout(()=>{
            setOpenPopUp(false)
          },5000)
        }
    }).
    catch(error => {
      actions.setSubmitting(false);
      if(error?.response?.data?.message === "UnAuthorized!"){
        var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        if(cookies[i] && !cookies[i].includes("view")){
          var equals = cookies[i].indexOf("=");
          var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }
      
      window.location.replace(`${BASE_URL}/logout`);
    }
    })
  }

  return (
      <React.Fragment>
        {openpopup?
          <div>
            <Alert variant="success">
                Your IFrame Theme Setting Updated Successfully..
            </Alert>
          </div>
        :""}

      <React.Fragment>
      <div className="row">
          <div className="col-12 mb-4 setting-title bg-white sticky-top d-flex justify-content-between pb-5 border-bottom" >
            IFRAME THEME SETTING
            <span className="cursor-pointer text-hover-primary"  onClick={() =>history.push({
                                                    pathname: "/diamondfeed"
                                                })}><BsArrowLeftShort /> Back to dashboard</span>
          </div>
          <div className="col-12">
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={currentValidationSchema}
                onSubmit={_handleSubmit}
            >
                {({ isSubmitting,setFieldValue, values }) => (
                <Form id={formId}>
                    <ThemeFrom formField={formField} values={values} setFieldValue={setFieldValue} language={props.language} iframesetting={props.iframesetting}/>
                    <Grid className="mt-10 sticky-top" container justify={"flex-end"} alignItems="center"  style={{bottom:"0px"}}>
                    <div className={classes.wrapper}>
                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          {props.language.SETTING_PASSWORD_SAVE}
                        </Button>
                        {isSubmitting && (
                          <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                          />
                        )}
                    </div>
                    </Grid>
                </Form>
                )}
            </Formik>
          </div>
      </div>
      </React.Fragment>
    </React.Fragment>
  );
}
