/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import teal from "material-ui/colors/teal";
import {
    Switch,
    Typography,
    withStyles
} from '@material-ui/core';
import PropTypes from "prop-types";
import axios from "axios";
import { NODE_API_URL } from "../../../env_config";
import { SwalWarn } from "../Popup";
import { TracklistTable } from "./TracklistTable";
import { TracklistedStone } from "./TracklistedStone";
import { TracklistedStoneWise } from "./TracklistedStoneWise"
function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const CustomSwitch = withStyles({
    switchBase: {
        color: teal[300],
        '&$checked': {
            color: teal[500],
        },
        '&$checked + $track': {
            backgroundColor: teal[500],
        },
    },
    checked: {},
    track: {},
})(Switch);

export const TracklistMaster = (props) => {
    const [shortlistSwitch, setShortlistSwitch] = useState(true)
    const [swalopen, setSwalOpen] = useState(false)
    const [deleteObj, setDeleteObj] = useState(null)
    const [selectedrow,setSelectedRow] = useState(null)
    // console.log(props.customeriframeorapi[0])
    const DeleteRule = () => {
        axios({
            method: "POST",
            url: `${NODE_API_URL}/EUR/iframeDeleteRules`,
            headers: { "Content-Type": "application/json" },
            data: {
                user_id: deleteObj.user_id,
                rule_id: deleteObj.rule_id
            },
        }).then(res => {
            if (res && res.data) {
                // console.log(res.data.data,"CCMODEEEEWHITELABEL")
                props.setUpdateRules(!props.updaterule)
                setSwalOpen(false)
                setDeleteObj(null)
            }
        })
    }
    return (
        <>
            <SwalWarn show={swalopen} message={"Are you sure want to delete Rule?"} onHide={() => setSwalOpen(false)} onClose={() => setSwalOpen(false)} responseOnOk={() => DeleteRule()} />
            <div className="card custom-card border-0">
                <div className="card-body">
                    <div className="row align-content-start position-relative" style={{ minHeight: "74vh" }}>
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <h3>Tracklist Page</h3>
                            <div className="d-flex align-items-center m-1 border rounded px-5">
                                <span className="font-weight-bold text-muted small">Stone Wise</span>
                                <CustomSwitch
                                    checked={shortlistSwitch}
                                    onClick={() => setShortlistSwitch(!shortlistSwitch)}
                                />
                                <span className="font-weight-bold text-muted small">Name Wise</span>
                            </div>
                        </div>
                        {shortlistSwitch?
                            <div className="col-12">
                                {!selectedrow?
                                    <TracklistTable language={props.language} conversionrate={props.conversionrate} symbol={props.symbol} userinfo={props.userinfo} setSelectedRow={setSelectedRow}/>
                                :
                                    <TracklistedStone userinfo={props.userinfo} language={props.language} conversionrate={props.conversionrate} symbol={props.symbol} selectedrow={selectedrow} setSelectedRow={setSelectedRow}/>}
                            </div>
                        :
                            <div className="col-12">
                                <TracklistedStoneWise userinfo={props.userinfo} language={props.language} conversionrate={props.conversionrate} symbol={props.symbol} setSelectedRow={setSelectedRow}/>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};
