import React, { useState ,useEffect} from 'react';
import {
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import axios from "axios"
import Cookies from "js-cookie"
import { BsArrowLeftShort } from 'react-icons/bs';
import MarkupCurrency from './CcmodeSetupNew/FormEdit/MarkupCurrency';
// import ReviewOrder from './ReviewOrder';
// import FormSuccess from '../../ccmode/CcmodeSetting/FormSuccess/FormSuccess';
import { NODE_API_URL,BASE_URL } from '../../../env_config';
import kycFormModel from './CcmodeSetupNew/FormModel/kycFormModel';
import useStyles from './CcmodeSetupNew/styles';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import validationSchemaEditTax from './CcmodeSetupNew/FormModel/validationSchemaEditTax';
const steps = [];
const { formId, formField } = kycFormModel;


export default function IFrameTaxSetting(props) {
  const history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [taxinclusive,SetTaxInclusiveValidation] = useState(false)
  // console.log(taxinclusive,"taxinclusive")
  const currentValidationSchema = validationSchemaEditTax(taxinclusive)[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [initialValues,setInitialValues] = useState({})
  const [openpopup,setOpenPopUp] = useState(false)
  useEffect(() => {
    // fetchData(props.userinfo.mail).then(values => {
    //   // console.log(values,"fetchData")
    //   setInitialValues(values)
    // })
    if(props.iframesetting && props.iframesetting.api_taxvalue){
      SetTaxInclusiveValidation(true)
    }
  },[props.iframesetting])
  // console.log(initialValues,"initialValues")
  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    // console.log("Submit")
  }

  function _handleSubmit(values, actions) {
    // console.log(values,"valuesvalues")
    actions.setSubmitting(false);
    let FinalData = {
      user_id:props.userinfo.id,
      data:[]
    }
   
    if(values.muti_currency){
      FinalData.data.push({api_currency:values.muti_currency})
    }
    if(values.noprice){
      FinalData.data.push({ifm_no_price:values.noprice})
    }
      // formData.append("TaxValue", values.taxinclusive?values.tax_value:0)
      FinalData.data.push({api_taxvalue:values.taxinclusive?values.tax_value:0})
      // formData.append("TaxName", values.taxinclusive?values.tax_name:"")
      FinalData.data.push({api_taxname:values.taxinclusive?values.tax_name:""})

      axios({
        method: "POST",
        url: `${NODE_API_URL}/EUR/update-iframe`,
        headers: {
          "Content-Type": "application/json",
           "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
        },
        data: FinalData,
      }).then(res => {
        // console.log(res,"RESPONSEEEE")
        actions.setSubmitting(false);
        if(res.data.success){
          // setActiveStep(activeStep + 1);
          // alert("Form Submitted")
          setOpenPopUp(true)
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
          setTimeout(()=>{
            setOpenPopUp(false)
          },5000)
        }
    }).
    catch(error => {
      actions.setSubmitting(false);
      if(error?.response?.data?.message === "UnAuthorized!"){
        var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        if(cookies[i] && !cookies[i].includes("view")){
          var equals = cookies[i].indexOf("=");
          var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }
      
      window.location.replace(`${BASE_URL}/logout`);
    }
    })
  }
  // const SetTaxInclusive = (values,setFieldValue) => {
  //   // console.log(values,"valuesvalues")
  //   setFieldValue("taxinclusive",!values.taxinclusive)
  //   SetTaxInclusiveValidation(!values.taxinclusive)
  //   if(values.taxinclusive){
  //     setFieldValue("tax_name","")
  //     setFieldValue("tax_value",0)
  //   }
  // }
  return (
    <React.Fragment>
          {openpopup?
            <div>
              <Alert variant="success">
                  Your IFrame Currency and Tax Setting Updated Successfully..
              </Alert>
            </div>
          :""}

      <React.Fragment>
      <div className="row">
          <div className="col-12 mb-4 setting-title d-flex justify-content-between pb-5 border-bottom">
            IFRAME TAX & CURRENCY SETTING
            <span className="cursor-pointer text-hover-primary"  onClick={() =>history.push({
                                                    pathname: "/diamondfeed"
                                                })}><BsArrowLeftShort /> Back to dashboard</span>
          </div>
          <div className="col-12">
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={currentValidationSchema}
                onSubmit={_handleSubmit}
            >
                {({ isSubmitting,setFieldValue, values }) => (
                <Form id={formId}>
                    <MarkupCurrency formField={formField} values={values} setFieldValue={setFieldValue} language={props.language} iframesetting={props.iframesetting}/>
                    <Grid className="mt-10 sticky-top" container justify={"flex-end"} alignItems="center"  style={{bottom:"0px"}}>
                      <div className={classes.wrapper}>
                          <Button
                              disabled={isSubmitting}
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.button}
                          >
                              {props.language.SETTING_PASSWORD_SAVE}
                          </Button>
                          {isSubmitting && (
                              <CircularProgress
                                  size={24}
                                  className={classes.buttonProgress}
                              />
                          )}
                      </div>
                    </Grid>
                </Form>
                )}
            </Formik>
          </div>
      </div>
      </React.Fragment>
    </React.Fragment>
  );
}
