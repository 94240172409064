/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect, useState} from 'react'
import { InvoiceDetailTable } from "../inoviceDetail/InvoiceDetailTable"
// import jsPDF from "jspdf";
import axios from 'axios';
import Cookies from "js-cookie"
import {
    Paper,
    Tabs,
    Tab,
    Typography
  } from "@material-ui/core";
  import PropTypes from "prop-types";
  import { useLocation } from 'react-router-dom';
import { NODE_API_URL,BASE_URL } from '../../../../env_config';
import ProformaSkeleton from './ProformaSkeleton'
import { BsArrowLeftShort } from "react-icons/bs";
import moment from "moment-timezone";
import { MdOutlineEmail } from 'react-icons/md';
import { FaRegFilePdf } from 'react-icons/fa';

  function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };

export const ProformaDetails = (props) => {
    const [value, setValue] = React.useState(0); // for tab

    function handleTabChange(event, newValue) {
        setValue(newValue);
    }
    const location = useLocation()
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const [totalinr,setTotalInr] = useState(0)
    const [totalinrcar,setTotalInrCar] = useState(0)
    const [totalcar,setTotalCar] = useState(0)
    const [ymdHms,setYmdHms] = useState("")
    const [performadata,setPerformaData] = useState()
    const [cgst,setCGST] = useState(0)
    const [sgst,setSGST] = useState(0)
    const [tax,setTAX] = useState(0)
    const [chargetax,setChargeTax] = useState(0)
    useEffect(() => {

        if(location.state.query){
            const finalData = {
                "id":location.state.query.perfoma_no,
                "conform_good_id":location.state.query.conform_good_id
            }
            axios({
                method: "get",
                url: `${NODE_API_URL}/EUR/proforma-detail-page`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                },
                params: finalData,
            }).then(res => {
                if(res && res.data){
                    // console.log(res.data,"DATATATAA")
                    setPerformaData(res.data)
                    setChargeTax((((res.data.convenience_fee + res.data.discount_extra_order) * 18)/100))
                    if(props.userinfo.state === "maharashtra"){
                        setCGST(((res.data.diamond_price_exchange * 0.75)/100))
                        setSGST(((res.data.diamond_price_exchange * 0.75)/100)) 
        
                    }else{
                        setTAX(((res.data.diamond_price_exchange * 1.5)/100))
                    }
                }
            }).catch(error => {
                if(error?.response?.data?.message === "UnAuthorized!"){
                  var cookies = document.cookie.split(";");
                for (var i = 0; i < cookies.length; i++) {
                  if(cookies[i] && !cookies[i].includes("view")){
                    var equals = cookies[i].indexOf("=");
                    var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                  }
                }
                
                window.location.replace(`${BASE_URL}/logout`);
              }
              })
        }
        else if(location.state.diamonddetail){
            let totalcarat = 0
            let totalinr = 0
            location.state.diamonddetail.map(values => {
                totalinr += parseFloat(values["OurPrice(INR)"])
                totalcarat += parseFloat(values["C_Weight"])
            })
            let totalinrcar = Math.round(totalinr/totalcarat * 100)/100
            setTotalInr(totalinr)
            setTotalInrCar(totalinrcar)
            setTotalCar(totalcarat)
            let date = new Date(2019, 4, 10, 15, 30, 20) //10 May 2019, 3:30:20 PM
            let dateStr = date.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "2-digit" }) // 10/05/19
    
            let arr = dateStr.split("/")// [ '10', '05', '19' ]
            let d = arr[0]; //e.g. 10 
            let m = arr[1]; //e.g. 5 
            let y = arr[2]; //e.g. 19
    
            let timeStr = date.toLocaleTimeString("en-GB", { hour12: false, hour: "2-digit", minute: "2-digit", second: "2-digit" }) //
            let arr2 = timeStr.split(":") // 15:30:20
            let H = arr2[0]; //e.g. 15
            let i = arr2[1]; //e.g. 30
            let s = arr2[2]; //e.g. 20
    
            let ymdHms = y + m + d + H + i + s;
            setYmdHms(ymdHms)
        }
    // const formData = new FormData();

        // axios({
        //     method: "POST",
        //     url: NODE_API_URL + "/EUR/insertPerfoma",
        //     headers: { "Content-Type": "application/json" },
        //     data: {
        //       "user_id":userinfo.id
        //     },
        //   }).then((res) => {
        //     if (res && res.data && res.data.success) {
        //         // console.log(res.data.data.isprofileapproved,"success")
        //         setProfileInfo(res.data.data)
        //     }
        //   });
    },[])
    const converttoyhmd = (newdate) => {
        let date = new Date(newdate) //10 May 2019, 3:30:20 PM
        let d = date.getUTCDate().toString().padStart(2, '0'); // Two-digit day
        let m = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Two-digit month
        let y = date.getUTCFullYear().toString().substr(-2); // Last two digits of the year

        let H = date.getUTCHours().toString().padStart(2, '0'); // Two-digit hour
        let i = date.getUTCMinutes().toString().padStart(2, '0'); // Two-digit minute
        let s = date.getUTCSeconds().toString().padStart(2, '0'); // Two-digit second

        let ymdHms = y + m + d + H + i + s;
        // setYmdHms(ymdHms)
        return ymdHms
    }

    const handleGeneratePdf = () => {
        if(process.env.REACT_APP_ENV === "production"){
            window.open(`https://assets.diamondsoncall.com/assets/pdf_invoice/${performadata.invoicelink}`, "_blank")
        }
        else{
        window.open(`https://alpha.diamondsupload.com/assets/pdf_invoice/${performadata.invoicelink}`, "_blank")

        }
    }
    const SendEmail = () => {
        if(performadata && performadata.expand && performadata.expand.length > 0){
            const finalData = {
                "proforma_no":location.state.query.perfoma_no,
                "conform_good_id":location.state.query.conform_good_id.split(',')
            }
            axios({
                method: "get",
                url: `${NODE_API_URL}/EUR/sendProfomaEmail`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                },
                params:finalData
            }).then(res => {
                if(res && res.data){
                    if(res.data.success){
                        alert("Mail Sent")
                    }
                }
            }).
            catch(error => {
              if(error?.response?.data?.message === "UnAuthorized!"){
                var cookies = document.cookie.split(";");
              for (var i = 0; i < cookies.length; i++) {
                if(cookies[i] && !cookies[i].includes("view")){
                  var equals = cookies[i].indexOf("=");
                  var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
              }
              
              window.location.replace(`${BASE_URL}/logout`);
            }
            })
        }
    }
    // const calculateGST = () => {
    //     console.log(props.userinfo,"INSIDE calculateGST")
    //     let cgst = 0
    //     let sgst = 0
    //     let tax = 0
    //     if(props.userinfo.state === "maharashtra"){
    //         let cgst = (totalinr * 0.75)/100
    //         let sgst = (totalinr * 0.75)/100 
    //         return {
    //             cgst:cgst,
    //             sgst:sgst
    //         }
    //     }else{
    //         return {
    //             tax:(totalinr * 1.5)/100
    //         }
    //     }
    // }
    const getaddress = () => {
        let shippingaddress = ""
        let billingaddress = ""
        if(performadata){
            if(performadata.company_name_official){
                shippingaddress += "<p><b>"
                shippingaddress += performadata.company_name_official
                shippingaddress += "</b></p>"
            }
            if(performadata?.name_atten){
                shippingaddress += "<p><b>"
                shippingaddress += performadata?.name_atten
                shippingaddress += "</b></p>"
            }
            if(performadata?.shipping_address_1){
              shippingaddress += "<p>"
              shippingaddress += performadata?.shipping_address_1 + ","
              shippingaddress += "</p>"
            }
            if(performadata?.shipping_address_2){
                shippingaddress += "<p>"
                shippingaddress += performadata?.shipping_address_2 + ","
                shippingaddress += "</p>"
            }
            if(performadata?.shipping_address_3){
                shippingaddress += "<p>"
                shippingaddress += performadata?.shipping_address_3 + ","
                shippingaddress += "</p>"
            }
            shippingaddress += "<p>"
            shippingaddress += performadata?.shipping_city + ", "
            if(performadata?.shipping_state){
                shippingaddress += performadata?.shipping_state + ", "
            }
            shippingaddress += performadata?.shipping_zip_code + ", "
            shippingaddress += "</p>"
            if(performadata?.shipping_country){
                shippingaddress += "<p>"
                shippingaddress += performadata?.shipping_country
                shippingaddress += "</p>"
            }
            if(performadata?.fedex_ac_no){
                shippingaddress += "<p>Fedex Ac No:"
                shippingaddress += performadata?.fedex_ac_no
                shippingaddress += "</p>"
            }
            if(performadata?.shipping_email){
                shippingaddress += "<p>Email:"
                shippingaddress += performadata?.shipping_email
                shippingaddress += "</p>"
            }
            if(performadata?.shipping_phone){
                shippingaddress += "<p>Phone:"
                shippingaddress += performadata?.shipping_phone
                shippingaddress += "</p>"
            }
        
            
        
            if(performadata.company_name_official){
                billingaddress += "<p><b>"
                billingaddress += performadata.company_name_official
                billingaddress += "</b></p>"
            }
            if(performadata.address){
              billingaddress += "<p>"
              billingaddress += performadata.address + ","
              billingaddress += "</p>"
            }
            if(performadata.secaddress){
                billingaddress += "<p>"
                billingaddress += performadata.secaddress + ","
                billingaddress += "</p>"
            }
            if(performadata.addressline3){
                billingaddress += "<p>"
                billingaddress += performadata.addressline3 + ","
                billingaddress += "</p>"
            }
            billingaddress += "<p>"
            billingaddress += performadata?.city + ", "
            if(performadata?.state){
                billingaddress += performadata?.state + ", "
            }
            billingaddress += performadata?.com_zip + ","
            billingaddress += "</p>"
            if(performadata.country){
                billingaddress += "<p>"
                billingaddress += performadata.country
                billingaddress += "</p>"
            }
            if(performadata.company_tax){
                billingaddress += "<p><b>Importers Ref:</b>"
                billingaddress += performadata.company_tax
                billingaddress += "</p>"
            }
        }
        return{
            shippingaddress:shippingaddress,
            billingaddress:billingaddress
        }
}
    return (
        <>
        
        {performadata? <>
            <div className="row bg-white rounded m-0 border-left-primary mb-5">
                <div className="col-12 px-5 py-2">
                    <span className="mb-0 font-size-h2 font-weight-bold d-block text-dark">{props.language.PROFORMA_DETAIL_DOCLLP}</span>
                    {/* <span className="text-muted">This page hase been enhanced for printing</span > */}
                </div>
            </div>
            <div className="card custom-card border-0 gutter-b">
                <div className="card-header bg-transparent border-0 py-3 px-5 d-flex justify-content-between align-items-center border-bottom-0">
                    <div className="card-title mb-0 ">
                        <span className="font-size-h2 font-weight-bold d-block">{props.language.PROFORMA_DETAIL_PROFORMA_INVOICE_DETAILS}: #{converttoyhmd(performadata.performadate)}</span>
                        <span className="text-muted d-block">
                            {props.language.PROFORMA_DETAIL_PROFORMA_INVOICED_ON} 
                            {/* {performadata.performadate} */}{" "}
                            { performadata.performadate ? moment.tz(performadata.performadate, props.userinfo.timezone ? props.userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"}
                            </span >
                    </div>
                    <div className="card-toolbar">
                        <span className="btn btn-text-dark btn-hover-light-dark  mr-3"onClick={() =>window.history.go(-1)}><BsArrowLeftShort className='mr-2 font-size-h5'/> {props.language.BACK}</span>
                        <button className="btn btn-text-dark btn-hover-light-primary  mr-3" onClick={SendEmail}><MdOutlineEmail className='mr-2 font-size-h5'/>{props.language.PROFORMA_DETAIL_SEND_EMAIL}</button>
                        <button className="btn btn-text-dark btn-hover-light-danger " onClick={handleGeneratePdf}><FaRegFilePdf className='mr-2 font-size-h5'/>{props.language.PROFORMA_DETAIL_SEND_PDF}</button>
                    </div>
                </div>
                <div className="card-body bg-white p-0 pb-8">
                    <Paper position="static" className="shadow-none border-bottom">
                        <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
                            <Tab label={props.language.PROFORMA_DETAIL_DETAILS} />
                            {/* <Tab label="History" disabled /> */}
                            {/* <Tab label="Additional Details"/> */}
                        </Tabs>
                    </Paper>
                     {value === 0 && (
                        <TabContainer >
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="font-size-h4  mb-2">{props.language.PROFORMA_DETAIL_SHIPPING_ADDRESS}</div>
                                    <div>
                                        {getaddress().shippingaddress ?  <div className="invAddress" dangerouslySetInnerHTML={ {__html:  getaddress().shippingaddress} } />: '-'}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="font-size-h4 font-weight-bold  mb-2">{props.language.PROFORMA_DETAIL_BILLING_ADDRESS}</div>
                                    <div>
                                        {getaddress().billingaddress?  <div className="invAddress" dangerouslySetInnerHTML={ {__html:  getaddress().billingaddress} } />:  '-'}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="font-size-h4 font-weight-bold mb-2">{props.language.PROFORMA_DETAIL_LOGISTICS_INFORMATION}</div>
                                    <div className="">{props.language.PROFORMA_DETAIL_CARRIER} :{performadata.shippingName}</div>
                                    {/* <div className="">{props.language.PROFORMA_DETAIL_TRACKING} :N/A</div> */}

                                    {/* <a href='#' target="_blank" rel='referal'>{props.language.PROFORMA_DETAIL_TRACK_YOUR_PACKAGE}</a> */}
                                </div>
                                <div className="col-md-3">
                                    <div className="font-size-h4 font-weight-bold mb-2">{props.language.PROFORMA_DETAIL_ORDER_SUMMARY}</div>
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-between">
                                            <span>{props.language.PROFORMA_DETAIL_DIAMONDS}:</span>
                                            <span>{performadata.totalItem} {props.language.PROFORMA_DETAIL_PCS}</span>
                                        </div>
                                        <div className="col-12 d-flex justify-content-between">
                                            <span>{props.language.SEARCHLIST_TOTALCARAT}:</span>
                                            <span>{numberWithCommas(performadata.totalCAR)}</span>
                                        </div>
                                        <div className="col-12 d-flex justify-content-between">
                                            <span>{props.language.SEARCHGRID_TOTAL} $/CT:</span>
                                            <span>${numberWithCommas(performadata.diamond_price_exchange/performadata.totalCAR)}</span>
                                        </div>
                                        <div className="col-12 d-flex justify-content-between">
                                            <span>Total Price:</span>
                                            <span>${numberWithCommas(performadata.diamond_price_exchange)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabContainer>
                    )}
                    {value === 1 && (
                        <TabContainer >
                            2
                        </TabContainer>
                    )}
                </div>
            </div>

            <div className="card custom-card  border-0 gutter-b">
                <div className="card-header bg-transparent py-5 border-bottom-0">
                    <div className="card-title mb-0 font-size-h4 font-weight-bold">{props.language.PROFORMA_DETAIL_DIAMONDS_INFORMATION}</div>
                </div>
                <div className="card-body p-8 rounded bg-white">
                    <InvoiceDetailTable diamonddetail={performadata.expand} language={props.language}/>
                    <div className="row p-5 mt-5">
                        <div className="col-md-9">
                            {/* <div className="font-size-h4 font-weight-bold mb-2">NOTES</div>
                            <div>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            </div> */}
                        </div>
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 '>USD/CT:</span>
                                    <span className='font-size-h5 '>${numberWithCommas(performadata.diamond_price_exchange/performadata.totalCAR)}</span>
                                </div>
                                <div className="col-12 mb-3 d-flex justify-content-between">
                                        <span className='font-size-h5 '>{props.language.INVOICE_DIAMOND_PRICE}:</span>
                                    <span className='font-size-h5 '>${numberWithCommas(performadata.diamond_price_exchange)}</span>
                                        </div>
                                        {performadata.discount_extra_order?<div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 '>{performadata.discount_extra_order>0?props.language.INVOICE_EXTRA_CHARGES:props.language.INVOICE_DISCOUNT}</span>
                                    <span className='font-size-h5 '>${numberWithCommas(performadata.discount_extra_order)}</span>
                                </div>:""}
                                        {performadata.shippingcharges?<div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 '>{props.language.REQ_DIAMOND_CONVINIENCE_FEE}:</span>
                                    <span className='font-size-h5 '>${numberWithCommas(performadata.shippingcharges)}</span>
                                </div>:""}
                                        

                                {/* {cgst?<div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 '>{props.language.PROFORMA_DETAIL_CGST}(0.75%):</span>
                                    <span className='font-size-h5 '>₹{numberWithCommas(cgst)}</span>
                                </div>:""}
                                {sgst?<div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 '>{props.language.PROFORMA_DETAIL_SGST}(0.75%):</span>
                                    <span className='font-size-h5 '>₹{numberWithCommas(sgst)}</span>
                                </div>:""} */}
                                {/* {tax?<div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 '>{props.language.PROFORMA_DETAIL_IGST}(1.5%):</span>
                                    <span className='font-size-h5 '>${numberWithCommas(tax)}</span>
                                </div>:""}
                                <div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 '>{props.language.PROFORMA_DETAIL_CHARGE_TAX}(18%):</span>
                                    <span className='font-size-h5 '>${numberWithCommas(chargetax)}</span>
                                </div> */}
                                {performadata.vip_discount?<div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 '>{props.language.INVOICE_SERVICE_CHARGE_DISCOUNT}:</span>
                                    <span className='font-size-h5  text-danger'>$-{numberWithCommas(parseFloat(performadata.vip_discount))}</span>
                                </div>:""}
                                <div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 font-weight-bold'>{props.language.REQ_TOTAL_DIAMOND_PRICE}:</span>
                                    <span className='font-size-h5 font-weight-bold'>${numberWithCommas(parseFloat(performadata.total_diamond_price_exchange))}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card custom-card-gutter-b">
                <div className="card-body">
                   <div className="row">
                        <div className="col-md-4">
                            <div className="font-size-h4 font-weight-bold mb-2">{props.language.PROFORMA_DETAIL_BANK_DETAILS}</div>
                            {performadata.perfoma_country === "IND-EXP"?<div className="font-size-lg">
                                <div>Bank Account Number : 121705002101</div>
                                <div>Bank Name : ICICI BANK</div>
                                <div>Bank Address : Bharat Diamond Bourse , BKC Mumbai,400051, MH, INDIA </div>
                                <div>AD CODE NO. 6391864-2910009</div>
                                <div>IFSC - ICIC0001217 SWIFT CODE -ICICINBBCTS</div>
                            </div>:<div className="font-size-lg">
                                <div>Account Name : Diamonds On Call India LLP</div>
                                <div>Bank Account Number : 39051002000</div>
                                <div>Bank Name : CITI BANK (HONG KONG) LIMITED</div>
                                <div>Bank Address : Citi Tower, 83 Hoi Bun Road, One Bay East, Kwun Tong</div>
                                <div>SWIFT Code : CITIHKAXXXX</div>
                                <div>INTERMEDIARY BANK : Citi Bank New York</div>
                            </div>}
                        </div >
                        <div className="col-md-8">
                            <div className="font-size-h4 font-weight-bold mb-3">{props.language.PROFORMA_DETAIL_TERMS}</div>
                            <div className="font-size-lg mb-3">
                                {props.language.PROFORMA_DETAIL_TERMS1}
                            </div>
                            <div className="font-size-lg mb-3">
                                {props.language.PROFORMA_DETAIL_TERMS2}
                            </div>
                            <div className="font-size-lg mb-3">
                                {props.language.PROFORMA_DETAIL_TERMS3}
                            </div>
                        </div>
                   </div >
                </div>
            </div>
            </>:<ProformaSkeleton /> }
        </>
    )
}