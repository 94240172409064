import React from "react";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { toAbsoluteUrl } from "../../../_helpers";
export const LazyImageComponent = (props) => {
    const history = useHistory()
    function handelDetails(row) {
        const url = `/detail/${row.data.diamondType}-${row.data.stockId}?SearchId=${props.data.search_result_id}`;
        window.open(url, '_blank');
    }
    const ImageUrl = (data) => {
        let image = ""
        if (data.ext_status === "Success") {
            image = data.ext_img_runtime
        }
        else if (data.aws_image) {
            image = data.aws_image
        }
        return image
    }
    return (
        <>
            {props.data.image ? <div>
                <LazyLoadImage
                    className="img-fluid cursor-pointer 1"
                    onClick={() => handelDetails(props)}
                    src={props.data.image}
                    alt={props.data.shape}
                    onError={e => {
                        e.currentTarget.src = toAbsoluteUrl(
                            `/media/shape/${props.data.shape.toLowerCase()}-no.png`
                        )
                    }}
                    effect="blur"
                />
            </div> : <div>
                <img
                    className="img-fluid cursor-pointer 2"
                    onClick={() => handelDetails(props)}
                    alt="shape"
                    src={toAbsoluteUrl(
                        `/media/shape/${props.data.shape.toLowerCase()}-no.png`
                    )}
                    onError={e => {
                        e.currentTarget.src = toAbsoluteUrl(
                            `/media/shape/noimage.jpg`
                        )
                    }}
                />
            </div>}
        </>
    );
};
