import React from 'react';
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {Switch,FormControlLabel  } from '@material-ui/core';
// import { NODE_API_URL } from '../../../../env_config';
// import axios from 'axios';
function countryGroup (x) {
    var country = x.split(',').map(function (x, index) {
        return <span className="label label-outline-primary label-pill label-inline bg-primary-o-30 px-5 mr-1" key={index}>{ x }</span>; 
    });

    return <span>{country}</span>;
}

// function dateFormate(date){
//     let formattedDate = "";
//     if (date) {
//         const dateObj = new Date(date);
//         const year = dateObj.getFullYear();
//         const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
//         const day = ('0' + dateObj.getDate()).slice(-2);
//         const timeString = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
//         formattedDate = `${year}-${month}-${day} ${timeString}`;
//     }
//     return formattedDate;
// }    
export const MockShortListColumn = (setUpdate,update,stockexpire,toast,language,setData,data,props) => [

    {
        Header:language.DIAMOND_FEED_LAB_LISTING_SUPPNAME,
        accessor:'supplier_name_o',
        className:"align-middle text-center",
    },
    {
        Header:language.SEARCHLIST_LOCATION,
        accessor: (d) => {
            return (
                <>
                    {d.diamond_countries === null && d.country === null 
                    ?<>-</>
                    :<>
                        {d.diamond_countries?countryGroup(d.diamond_countries):<span className="label label-outline-primary label-pill label-inline bg-primary-o-30 px-5">{d.country}</span>}
                    </>}
                </>
            );
        },
        className:"align-middle text-center",
    },
    {
        Header:language.PROFORMA_DETAIL_DIAMONDS,
        accessor: (d) => {
            return (
                <>
                    {/* {d.whitetotal > 0 ?<>White - {d.whitetotal} {d.fancytotal > 0 ?<>| Fancy - {d.fancytotal}</>:"" }</>:"White - 0" } */}
                    {d.whitetotal > 0 ?"White" + "-" + d.whitetotal.toString():"White - 0"} {d.fancytotal > 0 ?" Fancy" + "-" + d.fancytotal.toString():" Fancy - 0"} 
                </>
            );
        },
        className:"text-center align-middle",
    },
    // {
    //     Header:"Supplier Rule Status",
    //     accessor: (d) => {
    //         return (
    //             <>
    //                 { d.on_off === 1? <span class="label label-success label-pill label-inline px-5">Active</span>
    //                 : <span class="label label-danger label-pill label-inline px-5">In Active</span>
    //                  }
    //             </>
    //         );
    //     },
    //     className:"text-center align-middle",
    // },
    {
        Header:language.DIAMOND_FEED_LAB_LISTING_FEEDONOFF,
        accessor: (d) => {
            const handleChange = (e) => {
                props.setSupplierChanged(true)
                console.log(d,"DIAMOND_FEED_LAB_LISTING_FEEDONOFF")
                let switchvalue
                if(d.on_off === 1){
                    switchvalue = 2
                }else{
                    switchvalue = 1
                }
                let apidata = [...data]
                let supplierdata = [...props.supplierdata]
                for(let i = 0;i < apidata.length;i++){
                    if(apidata[i].supplier_name === d.supplier_name){
                        apidata[i].on_off = switchvalue
                        break;
                    }
                }
                setData(apidata)
                console.log(props,"propsprops")
                console.log(apidata,"apidata")
                const findsupplier = supplierdata.find(val => val.supplier_name === d.supplier_name)
                if(findsupplier){
                    for(let j = 0; j < supplierdata.length ; j++){
                        if(supplierdata[j].supplier_name === d.supplier_name){
                            supplierdata[j].on_off = switchvalue
                            break;
                        }
                    }
                }else{
                    supplierdata.push({
                        ...d,
                        on_off:switchvalue
                    })
                }
                props.setSupplierData(supplierdata)
                // console.log(switchvalue,"switchvalue",update)
                // axios({
                //     method: "POST",
                //     url: `${NODE_API_URL}/EUR/updateDataFromSupReqable`,
                //     headers: { "Content-Type": "application/json" },
                //     data: {
                //         "fields": [
                //             {
                //                 "api_on_off": switchvalue
                //             }
                //         ],
                //         "condition": [
                //             {
                //                 "sr_id": d.sr_id
                //             }
                //         ]
                //     },
                // }).then(supreq => {
                //     if(supreq && supreq.data){
                //         if(supreq.data){
                //         setUpdate(d)
                //         if(switchvalue === 1){
                //             toast.success(`Your Feed Turned on for ${d.supplier_name_o}`)
                //         }else{
                //             toast.error(`Your Feed Turned off for ${d.supplier_name_o}`)
                //         }
                //         }
                //     }
                // })
            }
            let checked = false
            let disabled = false
            if(d.on_off === 1){
                checked = true
            }
            // if(d.stock_access_status === 3 || d.status === 1 || d.stock_status === 1 || d.api_status === 2){
            //     disabled = true
            // }
            // else if(d.req_status == 1 && d.api_status == 1){
            //     disabled = false
                
            // }else if(d.stock_access_status == 2){
                
            //     disabled = true

            // }else{
            //     disabled = false
            // }
            return (
                <>
                     {/* <FormGroup row> */}
                     <FormControlLabel
                        className='m-0'
                        control={
                        <Switch
                            checked={checked}
                            onClick={handleChange}
                            value={d.api_on_off}
                            color="secondary"
                            // disabled={disabled}
                        />
                        }
                        // label="Primary"
                    />
                     {/* </FormGroup> */}
                </>
            );
        },
        className:"text-center align-middle",
    }
   
]