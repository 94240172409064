import React, { useState ,useEffect} from 'react';
import {
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import axios from "axios"
import { BsArrowLeftShort } from 'react-icons/bs';
import LoginFrom from './CcmodeSetupNew/FormEdit/LoginFrom';
// import ReviewOrder from './ReviewOrder';
// import FormSuccess from '../../ccmode/CcmodeSetting/FormSuccess/FormSuccess';
import { NODE_API_URL,BASE_URL } from '../../../env_config';
import Cookies from "js-cookie"
import kycFormModel from './CcmodeSetupNew/FormModel/kycFormModel';
import useStyles from './CcmodeSetupNew/styles';
import { Alert } from 'react-bootstrap';
import validationSchemaEditLogin from './CcmodeSetupNew/FormModel/validationSchemaEditLogin';
const { formId, formField } = kycFormModel;


export default function CcmodeLoginSetting(props) {
  const classes = useStyles();
  const [activeStep] = useState(0);
  const currentValidationSchema = validationSchemaEditLogin[activeStep];
  const [initialValues] = useState({})
  const [openpopup,setOpenPopUp] = useState(false)

  function _handleSubmit(values, actions) {
    // console.log(values,"valuesvalues")
    actions.setSubmitting(false);
    const formData = new FormData();
    
    if(props.userinfo.id){
      formData.append("CustomerId", props.userinfo.id)
    }

    if(values.email){
      formData.append("mail", values.email)
    }
    if(values.password){
      formData.append("Password", values.password)
    }

    axios({
      method: "POST",
      url: `${NODE_API_URL}/EUR/insertorupdateCCModeSetting`,
      headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
      },
      data: formData,
    }).then(res => {
        // console.log(res,"RESPONSEEEE")
        actions.setSubmitting(false);
        if(res.data.success){
          // setActiveStep(activeStep + 1);
          // alert("Form Submitted")
          setOpenPopUp(true)
          setTimeout(() => {
            const container = document.getElementById('themesetting');
            if (container) {
              container.scrollIntoView({ top: 0, left: 0, behavior: 'smooth' });
            }
          }, 100); // Adjust the delay time as needed
          setTimeout(()=>{
            setOpenPopUp(false)
          },5000)
        }
    }).catch(error => {
      if(error?.response?.data?.message === "UnAuthorized!"){
        var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        if(cookies[i] && !cookies[i].includes("view")){
          var equals = cookies[i].indexOf("=");
          var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }
      
      window.location.replace(`${BASE_URL}/logout`);
    }
    })
  }

  return (
    <React.Fragment>
          {openpopup?
            <div>
              <Alert variant="success">
                  Your Client-Centric Mode Password Updated Successfully..
              </Alert>
              <Alert variant="success">
                  Please login again in CC-Mode to see the changes..
              </Alert>
            </div>
          :""}

      <React.Fragment>
      <div className="row">
          <div className="col-12 sticky-top bg-white setting-title d-flex justify-content-between pb-5 border-bottom">
            CCMODE LOGIN SETTING
            <span className="cursor-pointer text-hover-primary"  onClick={() =>window.history.go(-1)}><BsArrowLeftShort /> Back to dashboard</span>
          </div>
          <div className="col-12">
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={currentValidationSchema}
                onSubmit={_handleSubmit}
            >
                {({ isSubmitting,setFieldValue, values }) => (
                <Form id={formId}>
                    <LoginFrom formField={formField} values={values} userinfo={props.userinfo} setFieldValue={setFieldValue} language={props.language} ccmodesetting={props.ccmodesetting}/>
                    <Grid className="mt-5 bg-white sticky-top" container justify={"flex-end"} alignItems="center"  style={{bottom:"0px"}}>
                    <div className={classes.wrapper}>
                        <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        >
                        {props.language.SETTING_PASSWORD_SAVE}
                        </Button>
                        {isSubmitting && (
                        <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                        />
                        )}
                    </div>
                    </Grid>
                </Form>
                )}
            </Formik>
          </div>
      </div>
      </React.Fragment>
    </React.Fragment>
  );
}
